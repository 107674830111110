import * as React from 'react'
import { firebase } from '../../firebase'
import { faComment } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

export class CommentButtonU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      commentCount: 0,
    }
  }

  async componentDidMount() {
    const postPath = this.props.post.path
    this.unsubscribe = await firebase.firestore
      .collection(`${postPath}/comments`)
      .onSnapshot(async snapshot => {
        const comments = snapshot.docs
        this.setState({ commentCount: comments.length })
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return (
      <span onClick={this.props.clickMethod} className="a-med mr-5 post-button">
        <FontAwesomeIcon icon={faComment} /> {this.state.commentCount}
      </span>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const CommentButton = withRouter(
  connect(mapStateToProps)(CommentButtonU),
)
