import * as React from 'react'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router'
import NUGLCross from './ico_cross.svg'

class SignNavigator extends React.PureComponent {
  navigate = destination => () => {
    this.props.history.push('/' + destination)
  }
  render() {
    return (
      <div>
        <img src={NUGLCross} alt="nugl cross" height="80" className="mb-3" />
        <b style={{ verticalAlign: 'top', fontWeight: 900, marginLeft: -15 }}>
          v2
        </b>
        <h3 className="mb-4">
          <span className="text-pink" style={{ fontSize: '120%' }}>
            2
          </span>
          GETHER we'll change the way we do social media.
        </h3>
        <Button
          onClick={this.navigate('signup')}
          type="button"
          className="btn-pink btn-block mb-3"
        >
          Sign Up
        </Button>
        <Button
          onClick={this.navigate('login')}
          type="button"
          className="btn-outline btn-block mb-5"
        >
          Log In
        </Button>
      </div>
    )
  }
}

export const SignNavigate = withRouter(SignNavigator)
