import * as React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { AuthorActions } from './AuthorActions'

class PostOptionDropdown extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }
  toggle = () => this.setState(p => ({ isOpen: !p.isOpen }))
  render() {
    /** Currently there are only actions for the author of the post. */
    if (
      !this.props.post ||
      !this.props.post.author ||
      !this.props.user ||
      this.props.post.author.id !== this.props.user.id
    ) {
      return null
    }
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
        <DropdownToggle
          style={{ backgroundColor: 'rgb(0,0,0,0)', border: 'none' }}
        >
          <FontAwesomeIcon color="#000" icon={faEllipsisH} size="lg" />
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem disabled>
                        This area is under construction.
                    </DropdownItem> */}
          {/* <DropdownItem disabled>
                        <FontAwesomeIcon icon={faLink} className="mr-2" /> Copy link
              </DropdownItem>
                    <DropdownItem disabled>
                        <FontAwesomeIcon icon={faEyeSlash} className="mr-2" /> Hide post
              </DropdownItem> */}
          {/* <DropdownItem disabled>
                        <FontAwesomeIcon icon={faBan} className="mr-2" /> Unfollow
                    </DropdownItem>
                    <DropdownItem disabled>
                        <FontAwesomeIcon icon={faFlag} className="mr-2" /> Report post
                    </DropdownItem> */}
          <AuthorActions
            post={this.props.post}
            profilePage={this.props.profilePage}
            editPost={this.props.editPost}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export { PostOptionDropdown }
