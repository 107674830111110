import * as React from 'react'
import { withRouter } from 'react-router'
import { CreatePost } from '../CreatePost'
import { FeedPosts } from '../FeedPosts'

class HomeFeedU extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <CreatePost feedId={this.props.profile.username} />
        <FeedPosts
          profile={this.props.profile}
          posts={this.props.posts}
          loading={this.props.loading}
        />
      </React.Fragment>
    )
  }
}

export const HomeFeed = withRouter(HomeFeedU)
