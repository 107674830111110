import * as React from 'react'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class BusinessRating extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      stars: [],
    }
  }

  componentWillMount() {
    let truncatedRating = Math.trunc(this.props.rating)

    for (let i = 0; i < truncatedRating; i++) {
      this.state.stars.push(
        <FontAwesomeIcon
          key={`star-rating-${i}`}
          icon={faStar}
          className="rating-star"
        />,
      )
    }
    if (this.props.rating > truncatedRating) {
      this.state.stars.push(
        <FontAwesomeIcon
          key={`star-rating-${truncatedRating}`}
          icon={faStarHalfAlt}
          className="rating-star"
        />,
      )
    }
  }

  render() {
    return (
      <li className="nav-item">
        <div className="nav-link">
          <div className="rating-stars">
            {this.state.stars}
            <small>
              <a href="#/" className="gray-link">
                {' '}
                {this.props.rating}
              </a>
            </small>
          </div>
        </div>
      </li>
    )
  }
}

export { BusinessRating }
