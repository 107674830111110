import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import NUGLCross from './ico_cross.svg'

import {
  Form,
  FormGroup,
  Button,
  FormText,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap'

import * as routes from '../../constants/routes'
import { firebase, auth } from '../../firebase'

const DEFAULT_USER = {
  avatar:
    'https://firebasestorage.googleapis.com/v0/b/nugl-2.appspot.com/o/images%2FNUGL.png?alt=media&token=caa26033-aadc-448f-b66c-730ce0f42d5c',
  cover:
    'https://firebasestorage.googleapis.com/v0/b/nugl-2.appspot.com/o/images%2Fnugl_cover_image.png?alt=media&token=225fdb08-839f-4dfa-b6a4-e6b11f237f51',
  details: '',
  headline: '',
  location: {
    city: '',
    state: 'beta.nugl.com!',
  },
  name: '',
  rating: 0,
}

class UnroutedSignUpForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      agree: false,
      email: '',
      error: null,
      isValid: false,
      passwordOne: '',
      passwordTwo: '',
      username: '',
      validationMessages: {},
      validationStates: {
        agree: false,
      },
    }
  }

  toUrlFriendlyName = name => {
    let friendlyName = name
      .replace(/[^a-z0-9\s-]/gim, '')
      .replace(/[-]/gim, ' ')
    let words = friendlyName.split(/\s+/g)
    friendlyName = words.join('-')
    friendlyName = friendlyName.toLowerCase()
    return friendlyName
  }

  change = type => async e => {
    if (type === 'agree') {
      const agree = !this.state.agree
      await this.setState({ agree })
      this.validate(type)(agree)
      return
    }
    const targetValue = e.target.value // Needed due to chrome event reuse
    const value =
      type === 'username' ? this.toUrlFriendlyName(targetValue) : targetValue
    await this.setState({ [type]: value })
    this.validate(type)(value)
  }

  setValidations = (type, validity, message) => {
    this.setState(p => {
      const newValidation = {
        ...p.validationStates,
        [type]: validity,
      }
      const isValid = Object.keys(newValidation).every(e => newValidation[e])
      return {
        isValid,
        validationStates: {
          ...p.validationStates,
          [type]: validity,
        },
        validationMessages: {
          ...p.validationMessages,
          [type]: message,
        },
      }
    })
  }

  isNotUnique = async username => {
    const doc = await firebase.firestore
      .collection('urls')
      .doc(username)
      .get()
    return doc.exists
  }

  validate = type => async value => {
    if (type === 'username') {
      // if (value !== "nugl") {
      //     this.setValidations(type, false, "Not Nugl")
      //     return;
      // }
      if (value.length < 4) {
        this.setValidations(
          type,
          false,
          'Username must be longer than 3 characters.',
        )
        return
      }
      if (value.length > 16) {
        this.setValidations(type, false, 'Username is too long.')
        return
      }
      if (value.split('').includes()) {
        this.setValidations(
          type,
          false,
          'Username must be shorter than 16 characters.',
        )
        return
      }
      if (await this.isNotUnique(value)) {
        this.setValidations(
          type,
          false,
          `Unfortunately, it seems that someone has this username already. https://beta.nugl.com/${value}`,
        )
        return
      }
    }
    if (type === 'email') {
      if (!value || value.indexOf('@') === -1 || value.indexOf('.') === -1) {
        this.setValidations(type, false, 'Not a valid email address.')
        return
      }
    }
    if (type.startsWith('password')) {
      if (!value || this.state.passwordOne !== this.state.passwordTwo) {
        this.setValidations('passwordOne', false, '')
        this.setValidations(
          'passwordTwo',
          false,
          'Something seems wrong with your passwords.',
        )
        return
      }
      if (value.length < 6) {
        this.setValidations(
          type,
          false,
          'Password too short. Need at least 6 characters.',
        )
        return
      }
      this.setValidations('passwordOne', true, 'Looks good.')
      this.setValidations('passwordTwo', true, 'Looks good.')
      return
    }
    if (type === 'agree') {
      if (!value) {
        this.setValidations(
          type,
          false,
          'Please agree to our terms and conditions.',
        )
        return
      }
    }
    this.setValidations(type, true, 'Looks good.')
  }

  onSubmit = async event => {
    event.preventDefault()
    const { email, passwordOne, username } = this.state
    const { history } = this.props
    try {
      const authUser = await auth.doCreateUserWithEmailAndPassword(
        email,
        passwordOne,
      )
      const user = {
        email,
        id: authUser.user.uid,
        timestamp: Date.now(),
        username,
      }
      const combinedUser = {
        ...DEFAULT_USER,
        ...user,
      }
      await firebase.firestore
        .doc(`users/${authUser.user.uid}`)
        .set(combinedUser)
      await firebase.firestore.doc(`people/${authUser.user.uid}`).set({
        ...DEFAULT_USER,
        id: authUser.user.uid,
        user: `users/${authUser.user.uid}`,
        username,
      })
      await firebase.firestore.doc(`urls/${username}`).set({
        user: `users/${authUser.user.uid}`,
      })
      this.props.setAppUser(combinedUser)
      history.push(routes.HOME)
    } catch (e) {
      console.error(e)
      this.setState({ error: e.message })
    }
  }

  navigate = route => () => this.props.history.push('/' + route)

  render() {
    return (
      <React.Fragment>
        <img src={NUGLCross} alt="nugl cross" height="80" className="mb-3" />
        <b style={{ verticalAlign: 'top', fontWeight: 900, marginLeft: -15 }}>
          v2
        </b>
        <h4 className="mb-3">Create your account</h4>
        <Form className="mb-5" onSubmit={this.onSubmit}>
          <FormGroup>
            <Label>Username</Label>
            <Input
              value={this.state.username}
              invalid={!this.state.validationStates.username}
              valid={this.state.validationStates.username}
              type="username"
              onChange={this.change('username')}
            />
            <FormFeedback
              invalid={!this.state.validationStates.username}
              valid={this.state.validationStates.username}
            >
              {this.state.validationMessages.username}
            </FormFeedback>
            <FormText>
              Your username will also be your personal NUGL link.
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label>Email address</Label>
            <Input
              value={this.state.email}
              invalid={!this.state.validationStates.email}
              valid={this.state.validationStates.email}
              type="email"
              onChange={this.change('email')}
            />
            <FormFeedback
              invalid={!this.state.validationStates.email}
              valid={this.state.validationStates.email}
            >
              {this.state.validationMessages.email}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              value={this.state.passwordOne}
              invalid={!this.state.validationStates.passwordOne}
              valid={this.state.validationStates.passwordOne}
              type="password"
              onChange={this.change('passwordOne')}
            />
            <FormFeedback
              invalid={!this.state.validationStates.passwordOne}
              valid={this.state.validationStates.passwordOne}
            >
              {this.state.validationMessages.passwordOne}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Verify Password</Label>
            <Input
              value={this.state.passwordTwo}
              invalid={!this.state.validationStates.passwordTwo}
              valid={this.state.validationStates.passwordTwo}
              type="password"
              onChange={this.change('passwordTwo')}
            />
            <FormFeedback
              invalid={!this.state.validationStates.passwordTwo}
              valid={this.state.validationStates.passwordTwo}
            >
              {this.state.validationMessages.passwordTwo}
            </FormFeedback>
          </FormGroup>
          <FormGroup check>
            <Input
              value={this.state.agree}
              invalid={!this.state.validationStates.agree}
              valid={this.state.validationStates.agree}
              type="checkbox"
              onChange={this.change('agree')}
              name="agree"
              defaultChecked={this.state.agree}
            />{' '}
            <Label for="agree">Agree to terms and conditions</Label>
            <FormFeedback invalid={!this.state.validationStates.agree}>
              {this.state.validationMessages.agree}
            </FormFeedback>
          </FormGroup>

          <Button disabled={!this.state.isValid} className="btn-pink btn-block">
            Sign Up
          </Button>
          {this.state.error}
          <Button
            onClick={this.navigate('login')}
            className="btn-outline btn-block"
          >
            Already have an account? Log in.
          </Button>
        </Form>
      </React.Fragment>
    )
  }

  setStateWithEvent(event, columnType) {
    this.setState(p => ({ [columnType]: event.target.value }))
  }
}

const mapDispatchToProps = dispatch => ({
  setAppUser: user => {
    return dispatch({ type: 'SET_USER', user })
  },
})

export const SignUpForm = withRouter(
  connect(
    () => ({}),
    mapDispatchToProps,
  )(UnroutedSignUpForm),
)
