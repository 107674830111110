import * as React from 'react'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '../shared/IconButton'

import { ProfileName } from './ProfileName'
import { ProfileHeadline } from './ProfileHeadline'
import { ProfileLocation } from './ProfileLocation'
import { Collapse } from 'reactstrap'
import { connect } from 'react-redux'

class UserProfileU extends React.PureComponent {
  render() {
    const editable = this.props.user.id === this.props.profile.id
    return (
      <div className="card mb-2">
        <div className="card-body profile-info">
          {editable && (
            <React.Fragment>
              <div
                className="profile-info-edit"
                data-toggle="tooltip"
                data-placement="top"
                title="Edit profile info"
              >
                <a
                  href="#/"
                  data-toggle="modal"
                  data-target="#profile_edit_dialog"
                >
                  <IconButton icon={faPen} />
                </a>
              </div>
            </React.Fragment>
          )}

          <ProfileName
            name={this.props.profile.name}
            username={this.props.profile.username}
          />

          <div className="dashboard-nav">
            <ul className="nav flex-column mb-0">
              <ProfileHeadline headline={this.props.profile.headline} />
              <ProfileLocation
                city={this.props.profile.location.city}
                state={this.props.profile.location.state}
              />
            </ul>
          </div>

          <div className="details-expander">
            <a
              data-toggle="collapse"
              href="#collapseDetails"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <span
                className="fa-stack"
                data-toggle="tooltip"
                data-placement="top"
                title="More details"
              >
                <i className="fas fa-circle fa-stack-2x text-gray" />
                <i className="rotate far fa-chevron-down fa-stack-1x fa-inverse" />
              </span>
            </a>
          </div>
          <Collapse>
            <p>
              NUGL is the world’s first cannabis search app built for the
              people, by the people. Our goal is to build the most user-friendly
              app experience in the cannabis industry by listening to our users
              and giving them what they want. NUGL is the only cannabis search
              app that offers equal and unbiased search results. We don’t sell
              top-spot listings or fake reviews, so our data stays true. Use
              NUGL to search for genuine user-rated dispensaries, brands,
              strains, services and more!
            </p>
          </Collapse>
          <div className="collapse" id="collapseDetails">
            <p>
              NUGL is the world’s first cannabis search app built for the
              people, by the people. Our goal is to build the most user-friendly
              app experience in the cannabis industry by listening to our users
              and giving them what they want. NUGL is the only cannabis search
              app that offers equal and unbiased search results. We don’t sell
              top-spot listings or fake reviews, so our data stays true. Use
              NUGL to search for genuine user-rated dispensaries, brands,
              strains, services and more!
            </p>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const UserProfile = connect(mapStateToProps)(UserProfileU)

export { UserProfile }
