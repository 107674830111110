import * as React from 'react'
import { Form, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import { connectSearchBox } from 'react-instantsearch-dom'

class SearchBoxU extends React.PureComponent {
  handleChange = e => {
    this.props.refine(e.target.value)
    this.props.onChange(e.target.value)
  }

  handlePress = e => {
    e.preventDefault()
    this.props.search()
    this.props.open()
  }

  render() {
    const { currentRefinement, placeholder } = this.props

    return (
      <Form inline className="search">
        <Input
          type="search"
          className="search-input"
          defaultValue={currentRefinement}
          placeholder={placeholder}
          onChange={this.handleChange}
          onClick={this.props.open}
          onBlur={this.props.search}
        />
        <span
          className="search-icon js-search-action"
          onClick={this.handlePress}
        >
          <button className="icon icon-medium nav-search" tabIndex={0}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </span>
      </Form>
    )
  }
}

export const SearchBox = connectSearchBox(SearchBoxU)
