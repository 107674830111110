import * as React from 'react'
import Linkify from 'linkify-it'
import tlds from 'tlds'

const linkify = new Linkify()
linkify.tlds(tlds)
linkify.add('@', {
  normalize: function(match) {
    match.url = 'https://beta.nugl.com/' + match.url.replace(/^@/, '')
  },
  validate: function(text, pos, self) {
    var tail = text.slice(pos)
    if (!self.re.twitter) {
      self.re.twitter = new RegExp(
        '^([a-zA-Z0-9_]){1,15}(?!_)(?=$|' + self.re.src_ZPCc + ')',
      )
    }
    if (self.re.twitter.test(tail)) {
      if (pos >= 2 && tail[pos - 2] === '@') {
        return false
      }
      return tail.match(self.re.twitter)[0].length
    }
    return 0
  },
})

class PostText extends React.PureComponent {
  cleanText = text => {
    if (text === '') {
      return text
    }
    const matches = linkify.match(text)
    if (!matches) {
      return text
    }
    const components = []
    let lastIndex = 0
    matches.forEach((match, i) => {
      if (match.index > lastIndex) {
        components.push(text.substring(lastIndex, match.index))
      }
      components.push(
        <a
          target="_blank"
          rel="noopener noreferrer"
          title={`Opens a new tab at: ${match.username}`}
          key={`link${lastIndex}-posts`}
          href={match.username}
        >
          {match.text}
        </a>,
      )
      lastIndex = match.lastIndex
    })
    if (text.length > lastIndex) {
      components.push(text.substring(lastIndex))
    }
    return components.length === 1 ? components[0] : components
  }
  render() {
    const { text } = this.props
    const cleanedText = this.cleanText(text)
    return <p>{cleanedText}</p>
  }
}

export { PostText }
