import * as React from 'react'
import { FeedPost } from '../FeedPost'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class FeedPostsU extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        {this.props.posts &&
          this.props.posts.map(post => (
            <FeedPost
              profile={this.props.profile}
              post={post}
              key={post.id + '-post-'}
            />
          ))}
        {!this.props.posts ||
        (this.props.posts.length === 0 && !this.props.loading)
          ? 'Nothing to show here!'
          : ''}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const FeedPosts = withRouter(connect(mapStateToProps)(FeedPostsU))
