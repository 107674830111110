import * as React from 'react'
import { auth } from '../../firebase'

export class PasswordChangeForm extends React.Component {
  INITIAL_STATE = {
    error: null,
    passwordOne: '',
    passwordTwo: '',
  }

  propKey(propertyName, value) {
    return { [propertyName]: value }
  }

  constructor(props) {
    super(props)
    this.state = { ...PasswordChangeForm.INITIAL_STATE }
  }

  onSubmit = event => {
    const { passwordOne } = this.state

    auth
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState(() => ({ ...PasswordChangeForm.INITIAL_STATE }))
      })
      .catch(error => {
        this.setState(PasswordChangeForm.propKey('error', error))
      })

    event.preventDefault()
  }

  render() {
    const { passwordOne, passwordTwo, error } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

    return (
      <form onSubmit={event => this.onSubmit(event)}>
        <input
          value={passwordOne}
          onChange={event => this.setStateWithEvent(event, 'passwordOne')}
          type="password"
          placeholder="New Password"
        />
        <input
          value={passwordTwo}
          onChange={event => this.setStateWithEvent(event, 'passwordTwo')}
          type="password"
          placeholder="Confirm New Password"
        />
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }

  setStateWithEvent(event, columnType) {
    this.setState(PasswordChangeForm.propKey(columnType, event.target.value))
  }
}
