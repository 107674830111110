import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PasswordChangeForm } from '../PasswordChange'
import { PasswordForgetForm } from '../PasswordForget/PasswordForgetForm'
import { withAuthorization } from '../Session/withAuthorization'

const AccountComponent = ({ authUser }) => (
  <div>
    <h1>Account: {authUser.email}</h1>
    <PasswordForgetForm />
    <PasswordChangeForm />
  </div>
)

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
})

const authCondition = authUser => !!authUser

export const Account = compose(
  withAuthorization(authCondition),
  connect(mapStateToProps),
)(AccountComponent)
