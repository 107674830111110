import * as React from 'react'
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ProfileLocation extends React.PureComponent {
  render() {
    return (
      <li className="nav-item">
        <a className="nav-link" href="#/">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="profile-location-icon"
          />{' '}
          {this.props.city}
          {this.props.city ? ',' : ''} {this.props.state}
        </a>
      </li>
    )
  }
}

export { ProfileLocation }
