import { firebase } from '.'

const markAllNotificationsAsRead = async userId => {
  try {
    const notifications = await firebase.firestore
      .collection(`notifications/${userId}/queue`)
      .where('read', '==', false)
      .get()
    if (notifications.empty) {
      return
    }
    notifications.forEach(n => {
      n.ref.set({ read: true }, { merge: true })
    })
  } catch (e) {
    console.error('Error marking all notifications as read.', e)
  }
}

const markAsRead = async path => {
  return await firebase.firestore.doc(path).set({ read: true }, { merge: true })
}

const notificationSub = userId =>
  firebase.firestore.collection(`notifications/${userId}/queue`)

export { markAllNotificationsAsRead, markAsRead, notificationSub }
