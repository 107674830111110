import * as React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap'
import { firebase } from '../../firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

class FeedbackFormModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      feedback: '',
      showClose: false,
    }
  }
  updateFeedback = e => {
    this.setState({ feedback: e.target.value })
  }
  submit = async () => {
    await firebase.firestore.collection('feedback').add({
      ...this.state,
      email: this.props.user.email,
      timestamp: Date.now(),
      user: await firebase.firestore.doc('users/' + this.props.user.id),
    })
    await firebase.firestore.doc(`users/${this.props.user.id}`).set(
      {
        feedbackGiven: true,
      },
      { merge: true },
    )
    this.setState({
      showClose: true,
    })
  }
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.close}>
        <ModalHeader toggle={this.props.close}>Report an issue</ModalHeader>
        <ModalBody>
          <p>
            Please report any issues you find in our software, and we'll do our
            best to fix it as soon as possible!
          </p>
          <Input
            type="textarea"
            value={this.state.feedback}
            onChange={this.updateFeedback}
          />
        </ModalBody>
        <ModalFooter>
          {!this.state.showClose && (
            <Button onClick={this.submit}>Submit</Button>
          )}
          {this.state.showClose && (
            <Button active onClick={this.props.close}>
              <FontAwesomeIcon icon={faCheck} /> Submitted. Close?
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }
}

export { FeedbackFormModal }
