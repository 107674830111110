import * as React from 'react'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class ProfileBusinessType extends React.PureComponent {
  render() {
    if (!this.props.types || !this.props.types.length) {
      return <div>No types</div>
    }
    return (
      <li className="nav-item">
        <div href="#/" className="nav-link">
          <FontAwesomeIcon
            icon={faBriefcase}
            className="profile-business-type-icon"
          />
          <a href="#/">{this.props.types[0]}</a> /{' '}
          <a href="#/">{this.props.types[1]}</a>
        </div>
      </li>
    )
  }
}

export { ProfileBusinessType }
