import * as React from 'react'
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardImgOverlay,
  CardTitle,
} from 'reactstrap'
import Future from '../ProfileContent/SuggestedProfilesPlaceholder.png'

class SuggestedProfiles extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card inverse>
          <CardImg src={Future} width="100%" />
          <CardImgOverlay
            style={{
              backgroundColor: '#000',
              opacity: '0.7',
            }}
          >
            <CardBody>
              <CardTitle
                className="card-title"
                style={{
                  fontSize: '1.5em',
                  margin: '0px auto',
                }}
              >
                Suggested profiles coming soon!
              </CardTitle>
              <CardText>
                Thanks for testing out NUGL 2.0 beta. We welcome all feedback as
                we continue to make the site better.
              </CardText>
            </CardBody>
          </CardImgOverlay>
        </Card>
      </React.Fragment>
    )
  }
}

export { SuggestedProfiles }
