import * as React from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoggedOutDropdownMenu = () => (
  <DropdownMenu right>
    <Link to={'/signup'}>
      <DropdownItem>
        <FontAwesomeIcon icon={['far', 'user-plus']} className="mr-2" />
        Sign up
      </DropdownItem>
    </Link>
    <Link to={'/login'}>
      <DropdownItem>
        <FontAwesomeIcon icon={['far', 'sign-in']} className="mr-2" />
        Log in
      </DropdownItem>
    </Link>
  </DropdownMenu>
)

const LoggedInDropdownMenu = ({ name, username }) => (
  <DropdownMenu right>
    <DropdownItem header={true} className="dropdown-account-header">
      <p className="m-0">
        <b>{name || username}</b>
      </p>
      <Link to={'/' + username}>@{username}</Link>
    </DropdownItem>
    <DropdownItem divider={true} />
    <a href="/home">
      <DropdownItem>
        <FontAwesomeIcon icon={['far', 'home']} className="mr-2" />
        Home
        <i className="open-new-tab-icon-placeholder" />
      </DropdownItem>
    </a>
    <Link to={'/' + username}>
      <DropdownItem>
        <FontAwesomeIcon icon={['far', 'user']} className="mr-2" />
        Profile
        <i className="open-new-tab-icon-placeholder" />
      </DropdownItem>
    </Link>
    {/*
    <DropdownItem divider={true} />
    <DropdownItem header={true} className="dropdown-account-header">
        Public Profiles
                </DropdownItem>
    <div className="dropdown-businesses">
        <DropdownItem>
            <Link to={"/" + username}>
                <img src={avatar} alt="user_sample_01" className="mr-1" />
                {name || username}
            </Link>
        </DropdownItem>
    </div>
    */}
    <DropdownItem divider={true} />
    <DropdownItem disabled>
      <FontAwesomeIcon icon={['far', 'plus']} className="mr-2" />
      Add business
    </DropdownItem>
    <DropdownItem divider={true} />
    <a href="https://company.nugl.com/advertise.html">
      <DropdownItem>
        <FontAwesomeIcon icon={['far', 'external-link']} className="mr-2" />
        NUGL Ads
        <i className="open-new-tab-icon-placeholder" />
      </DropdownItem>
    </a>
    <DropdownItem disabled={true}>
      <FontAwesomeIcon icon={['far', 'chart-bar']} className="mr-2" />
      Analytics
      <i className="open-new-tab-icon-placeholder" />
    </DropdownItem>
    <DropdownItem divider={true} />
    <DropdownItem disabled={true}>
      Report an issue
      <i className="open-new-tab-icon-placeholder" />
    </DropdownItem>
    <DropdownItem href="/signout">Log out</DropdownItem>
  </DropdownMenu>
)

class AccountDropdown extends React.PureComponent {
  signUp = () => {
    this.props.history.push('/signup')
  }

  render() {
    return (
      <Dropdown isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <DropdownToggle
          nav
          className="user-avatar"
          caret={true}
          style={{
            padding: '0',
          }}
        >
          {this.props.user && this.props.user.avatar ? (
            <img
              src={this.props.user.avatar}
              alt="user_avatar"
              className="img-round"
            />
          ) : (
            <FontAwesomeIcon
              className="avatar-icon"
              size="2x"
              icon={faUserCircle}
            />
          )}
        </DropdownToggle>
        {this.props.user && this.props.user.id ? (
          <LoggedInDropdownMenu
            avatar={this.props.user.avatar}
            username={this.props.user.username}
            name={this.props.user.name}
          />
        ) : (
          <LoggedOutDropdownMenu />
        )}
      </Dropdown>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export default withRouter(connect(mapStateToProps)(AccountDropdown))
