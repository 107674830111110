import * as React from 'react'
import { firebase } from '../../firebase'
import { faHeart as filledHeart } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as emptyHeart } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class LikeButtonU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      liked: false,
      loaded: false,
      count: 0,
    }

    this.toggleLike = this.toggleLike.bind(this)
  }

  async componentDidMount() {
    const postPath = this.props.post.path
    this.unsubscribe = await firebase.firestore
      .collection(`${postPath}/likes`)
      .onSnapshot(async snapshot => {
        const likes = snapshot.docs
        this.setState({
          count: likes.length,
          liked: likes.some(l => l.data().author.id === this.props.profile.id),
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  toggleLike() {
    this.setState(previousState => ({
      liked: !previousState.liked,
      count: previousState.liked
        ? previousState.count - 1
        : previousState.count + 1,
    }))

    this.state.liked ? this.removeLike() : this.submitLike()
  }

  submitLike = async e => {
    const timestamp = Date.now()

    await firebase.firestore
      .doc(`${this.props.post.path}/likes/${this.props.user.id}`)
      .set(
        {
          author: firebase.firestore.doc(`people/${this.props.user.id}`),
          notifier: firebase.firestore.doc(`people/${this.props.user.id}`),
          timestamp,
        },
        { merge: true },
      )
  }

  removeLike = async e => {
    await firebase.firestore
      .doc(`${this.props.post.path}/likes/${this.props.user.id}`)
      .delete()
  }

  render() {
    return (
      <span
        onClick={this.toggleLike}
        className="pink-hover a-med mr-5 post-button"
      >
        <FontAwesomeIcon
          icon={this.state.liked ? filledHeart : emptyHeart}
          className={`${this.state.liked && 'text-pink'}`}
        />{' '}
        {this.state.count}
      </span>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const LikeButton = withRouter(connect(mapStateToProps)(LikeButtonU))
