import * as React from 'react'
import { Link } from 'react-router-dom'
import { format } from 'timeago.js'
import { CommentOptions } from './CommentOptions'
import { EditComment } from './EditComment'

import { firebase } from '../../firebase'

class PostComment extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  like = async e => {
    e.preventDefault()
    const allLikes = this.props.allLikes ? this.props.allLikes : []
    const newLikes = this.props.liked
      ? allLikes.filter(l => l !== this.props.userId)
      : [...allLikes, this.props.userId]
    await firebase.firestore.doc(this.props.path).set(
      {
        liked: newLikes,
        notifier: firebase.firestore.doc(`people/${this.props.userId}`),
      },
      { merge: true },
    )
  }

  delete = async e => {
    e.preventDefault()
    await firebase.firestore.doc(this.props.path).delete()
  }

  editToggle = () => {
    this.setState(p => ({ editing: !p.editing }))
  }

  render() {
    return (
      <div className="post-comment">
        <div className="media">
          <img
            className="rounded-circle img-fluid mr-2"
            src={this.props.avatar}
            alt=""
          />
          <div className="media-body">
            {this.state.editing ? (
              <EditComment
                text={this.props.text}
                path={this.props.path}
                toggle={this.editToggle}
              />
            ) : (
              <div className="comment-bubble">
                <div className="comment-content">
                  <Link
                    to={'/' + this.props.username}
                    className="comment-name"
                    style={{ marginRight: '7px' }}
                  >
                    {this.props.name}
                  </Link>
                  <span className="comment-text">{this.props.text}</span>
                </div>
              </div>
            )}
            <div className="comment-actions">
              <Link to="#" onClick={this.like}>
                {this.props.liked ? 'Unlike' : 'Like'}
              </Link>
              {' · '}
              <span title="Threaded comments coming soon!">Reply</span>
              {' · '}
              {format(this.props.timestamp)}
              {this.props.modified &&
                ` (edited ${format(this.props.modified)})`}
            </div>
          </div>
          <CommentOptions
            author={this.props.isAuthor}
            delete={this.delete}
            edit={this.editToggle}
          />
        </div>
      </div>
    )
  }
}

export { CreateComment } from './CreateComment'
export { PostComment }
