import * as React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { faUserFriends } from '@fortawesome/pro-regular-svg-icons'

export const Following = withRouter(
  class Following extends React.PureComponent {
    render() {
      const { className, following } = this.props
      return (
        <div className={className}>
          <div className="card mb-2">
            <div className="card-body">
              <h5 className="card-title">
                <span className="fa-stack">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="fa-stack-2x text-darkgray"
                  />
                  <FontAwesomeIcon
                    icon={faUserFriends}
                    className="fa-stack-1x"
                    inverse
                  />
                </span>{' '}
                Following
                <small className="float-right">
                  {/* <a href="#/">See all</a> */}
                </small>
              </h5>
              {following &&
                following.map(person => (
                  <div key={person.id} className="usercard-container">
                    <div className="usercard" id="profileInfo">
                      <div className="media align-items-center">
                        <div className="usercard-picture">
                          <Link
                            className="usercard-title"
                            to={'/' + person.username}
                          >
                            <img
                              src={person.avatar}
                              alt={person.username}
                              className="img-round rounded-circle"
                            />
                          </Link>
                        </div>
                        <div className="media-body">
                          <div className="usercard-details">
                            <p className="truncate">
                              <Link
                                className="usercard-title"
                                to={'/' + person.username}
                              >
                                {person.name || person.username}
                              </Link>
                            </p>
                            {person.headline && (
                              <p className="truncate usercard-info">
                                {person.headline}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )
    }
  },
)
