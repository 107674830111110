import * as React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <div className="profile-footer">
        <span className="mr-2">© 2019 NUGL</span>
        <a href="https://company.nugl.com/about.html" className="mr-2">
          About{' '}
        </a>
        <a href="https://company.nugl.com/contact.html" className="mr-2">
          Help&nbsp;Center
        </a>
        <a href="https://company.nugl.com/terms.html" className="mr-2">
          Terms{' '}
        </a>
        <a href="https://company.nugl.com/privacy.html" className="mr-2">
          Privacy&nbsp;policy
        </a>
        <a href="https://company.nugl.com/contact.html" className="mr-2">
          Contact{' '}
        </a>
        <a href="https://company.nugl.com/advertise.html" className="mr-2">
          Ads&nbsp;info{' '}
        </a>
      </div>
    )
  }
}

export { Footer }
