import * as React from 'react'
import { Container } from 'reactstrap'

class ProfileImage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  render() {
    return (
      <Container style={{ position: 'relative' }}>
        <div className="profile-picture-wrapper">
          <div className="profile-picture">
            <img src={this.props.avatar} alt="profile_avatar" />
          </div>
          {/* <div className="profile-picture-edit">
                        <IconButton icon={faCamera} />
                        <input id="file-input" type="file" />
                    </div> */}
        </div>
      </Container>
    )
  }
}

ProfileImage.defaultProps = {
  avatar: 'http://nugl.goboxy.com/assets/img/default/nugl_launcher.png',
}

export { ProfileImage }
