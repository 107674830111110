import * as React from 'react'
import { Form, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

import { connectSearchBox } from 'react-instantsearch-dom'

class SearchBoxU extends React.PureComponent {
  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    } else {
      this.props.refine(e.currentTarget.value)
    }
  }
  render() {
    const { currentRefinement, className, placeholder } = this.props
    return (
      <Form inline className="search">
        <Input
          type="search"
          className={className}
          defaultValue={currentRefinement}
          placeholder={placeholder}
          onKeyPress={this.handleKeyPress}
        />
        <FontAwesomeIcon icon={faSearch} className="search-magnifier" />
      </Form>
    )
  }
}

export const SearchBox = connectSearchBox(SearchBoxU)
