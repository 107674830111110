import * as React from 'react'
import { connect } from 'react-redux'

const NavigationComponent = () => <NavigationNonAuth />

const NavigationNonAuth = () => {
  return (
    <nav className="d-block d-sm-block d-md-none navbar navbar-dark nugl-nav fixed-bottom navbar-expand">
      <ul className="w-100 nav-justified navbar-nav mr-auto">
        <li className="nav-item">
          <a className="nav-link" href="user_profile_home.html">
            <i className="far fa-home" />
          </a>
        </li>
        <li className="nav-item active">
          <a className="nav-link" href="business_profile_feed.html">
            <i className="fas fa-user" />
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#/">
            <i className="far fa-chart-bar" />
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#/">
            <i className="far fa-bell" />
            <span className="badge badge-pill badge-pink">2</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

const mapStateToProps = state => ({
  authUser: state.sessionState.authUser,
})

export const Navigation = connect(mapStateToProps)(NavigationComponent)

export { WideWeb as WideWebNavigation } from './WideWeb'
