import * as React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faCircle,
  faComments,
  faEllipsisH,
  faPlus,
  faUser,
  faUserFriends,
} from '@fortawesome/pro-regular-svg-icons'

class Navigation extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  render() {
    const { section, profile, badgeCount } = this.props
    return (
      <React.Fragment>
        <div className="profile-info-edit">
          {false && (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle
                tag="span"
                onClick={this.toggle}
                data-toggle="dropdown"
                aria-expanded={this.state.dropdownOpen}
              >
                <a
                  href="#/"
                  className="dropdown-toggle"
                  id="dropdownPostEdit"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="fa-layers fa-lg">
                    <FontAwesomeIcon icon={faCircle} size="lg" />
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </span>
                </a>
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-businesses">
                  <a className="dropdown-item" href="#/">
                    <img
                      src="https://nugl-s3.imgix.net/influencers/lil-eazy-e/f8384e77-88c1-493a-b5ef-ca328e7c5458.jpg"
                      alt=""
                      className="img-round mr-1"
                    />
                    NUGL Inc
                  </a>
                  <a className="dropdown-item" href="#/">
                    <img
                      src="https://nugl-s3.imgix.net/influencers/lil-eazy-e/f8384e77-88c1-493a-b5ef-ca328e7c5458.jpg"
                      alt=""
                      className="img-round mr-1"
                    />
                    Rich &amp; Ruthless
                  </a>
                  <a className="dropdown-item" href="#/">
                    <img
                      src="https://nugl-s3.imgix.net/influencers/lil-eazy-e/f8384e77-88c1-493a-b5ef-ca328e7c5458.jpg"
                      alt="user_sample_01"
                      className="img-round mr-1"
                    />
                    LiL Eazy E
                  </a>
                </div>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="#/">
                  <FontAwesomeIcon icon={faPlus} color="#657786" size="sm" />
                  <span style={{ marginLeft: 5 }}>Add business</span>
                </a>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        <div
          className="profile-cover-home"
          style={{ backgroundImage: `url(${profile.cover}})` }}
        />
        <div className="usercard-container user-card-home">
          <div className="usercard pt-3 pr-3" id="profileInfo">
            <div className="media align-items-center">
              <Link to={`/${profile.username}`}>
                <div className="usercard-picture">
                  <img
                    src={profile.avatar}
                    alt={profile.username}
                    className="img-round"
                  />
                </div>
              </Link>
              <div className="media-body">
                <div className="usercard-details">
                  <p className="truncate">
                    <Link
                      to={`/${profile.username}`}
                      className="usercard-title"
                    >
                      {profile.name || profile.username}
                    </Link>
                  </p>
                  <p>
                    <Link to={`/${profile.username}`} className="usercard-user">
                      @{profile.username}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-nav">
          <ul className="nav flex-column mb-0">
            <li className="nav-item">
              <Link to={`/${profile.username}`} className="nav-link">
                <FontAwesomeIcon icon={faUser} className="mr-1" />
                <span>View Profile</span>
              </Link>
            </li>
            <li
              className={classNames('nav-item', {
                active: !section || section === 'chatter',
              })}
            >
              <Link to={`/home`} className="nav-link">
                <FontAwesomeIcon icon={faComments} className="mr-1" />
                <span>Chatter</span>
                {badgeCount.chatter > 0 && (
                  <span className="ml-1 badge badge-pill badge-pink">
                    {badgeCount.chatter}
                  </span>
                )}
              </Link>
            </li>
            <li
              className={classNames('nav-item', {
                active: section === 'following',
              })}
            >
              <Link to={`/home/following`} className="nav-link">
                <FontAwesomeIcon icon={faUserFriends} className="mr-1" />
                <span>Following</span>
                {badgeCount.following > 0 && (
                  <span className="ml-1 badge badge-pill badge-pink">
                    {badgeCount.following}
                  </span>
                )}
              </Link>
            </li>
            <li
              className={classNames('nav-item', {
                active: section === 'notifications',
              })}
            >
              <Link to={`/home/notifications`} className="nav-link">
                <FontAwesomeIcon icon={faBell} className="mr-1" />
                <span>Notifications</span>
                {badgeCount.notifications > 0 && (
                  <span className="ml-1 badge badge-pill badge-pink">
                    {badgeCount.notifications}
                  </span>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
}

export { Navigation }
