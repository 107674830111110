import * as React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { Notification } from './Notification'
import { markAllNotificationsAsRead } from '../../firebase'

class NotificationCenter extends React.PureComponent {
  markAllAsRead = () => {
    markAllNotificationsAsRead(this.props.userId)
  }

  render() {
    if (!this.props.loaded) {
      return null
    }
    return (
      <React.Fragment>
        <Card className="mb-2">
          <CardBody className="notification-cards">
            <CardTitle>
              <span className="fa-stack">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="fa-stack-2x text-darkgray"
                />
                <FontAwesomeIcon
                  icon={faBell}
                  className="fa-stack-1x"
                  inverse
                />
              </span>{' '}
              Notifications
              {this.props.unreadCount > 0 && (
                <small className="float-right">
                  <a href="#/" onClick={this.markAllAsRead}>
                    Mark all as read
                  </a>
                </small>
              )}
            </CardTitle>
            {this.props.notifications.length > 0 &&
              this.props.notifications.map(n => {
                return (
                  <Notification
                    key={`notification-center-${n.id}`}
                    notification={n}
                    path={n.path}
                  />
                )
              })}
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

/**
 * Once in home page, can be removed
 */
class TempWrapperNotificationCenter extends React.PureComponent {
  render() {
    if (!this.props.user) {
      return null
    } else {
      return <NotificationCenter {...this.props} />
    }
  }
}

const mapStateToProps = state => ({
  user: state.user.id,
})

const ConnectedNotificationCenter = connect(mapStateToProps)(
  TempWrapperNotificationCenter,
)

export { ConnectedNotificationCenter as NotificationCenter }

//     render: function () {
//         return (

//             <div id="followingList" className="col-md-8 col-xl-6 px-1">
//                 <div className="card mb-2">
//                     <div className="card-body notification-cards">
//                         <h5 className="card-title">
//                             <a href="#/" className="a-dark">
//                                 <span className="fa-stack">
//                                     <i className="fas fa-circle fa-stack-2x text-darkgray" />
//                                     <i className="far fa-bell fa-stack-1x fa-inverse" />
//                                 </span>
//                                 Notifications
//                 </a>
//                             <small className="float-right"><a href="#/">Mark all as read</a></small>
//                         </h5>
//                         <div className="usercard-container unread">
//                             <div className="usercard" id="profileInfo">
//                                 <div className="row align-items-center no-gutters">
//                                     <div className="col-11">
//                                         <a href="#/" data-toggle="modal" data-target="#notification_modal" className="big-link">
//                                             <div className="media align-items-center">
//                                                 <div className="usercard-picture">
//                                                     <img src="assets/img/default/nugl_launcher.png" alt="user_sample_01" className="img-round" />
//                                                 </div>
//                                                 <div className="media-body">
//                                                     <div className="usercard-details">
//                                                         <p className="truncate">
//                                                             <b>NUGL Inc</b> liked your post: "Each profile can make connections and build a network of friends in the NUGL community! Get on #nugl today and make connections just like CJ Melone and E3"
//                               </p>
//                                                         <p>
//                                                             <span className="usercard-info"><i className="text-pink fas fa-heart" /> 13h</span>
//                                                         </p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </a>
//                                     </div>
//                                     <div className="col-1">
//                                         <div className="notification-options">
//                                             <div className="dropdown text-right">
//                                                 <button className="dropdown-toggle" id="dropdownPostEdit" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                     <span className="fa-stack fa-lg">
//                                                         <i className="far fa-ellipsis-h fa-stack-1x text-dark" />
//                                                     </span>
//                                                 </button>
//                                                 <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownPostEdit">
//                                                     <a className="dropdown-item" href="#/">Mark as read</a>
//                                                     <a className="dropdown-item" href="#/">Remove this notification</a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="usercard-container">
//                             <div className="usercard" id="profileInfo">
//                                 <div className="row align-items-center no-gutters">
//                                     <div className="col-11">
//                                         <a href="#/" data-toggle="modal" data-target="#notification_modal" className="big-link">
//                                             <div className="media align-items-center">
//                                                 <div className="usercard-picture">
//                                                     <img src="assets/img/samples/user_sample_01.png" alt="user_sample_01" className="img-round" />
//                                                 </div>
//                                                 <div className="media-body">
//                                                     <div className="usercard-details">
//                                                         <p className="truncate">
//                                                             <b>Rich &amp; Ruthless</b> commented on your post: "Each profile can make connections and build a network of friends in the NUGL community! Get on #nugl today and make connections just like CJ Melone and E3"
//                               </p>
//                                                         <p>
//                                                             <span className="usercard-info"><i className="text-blue fas fa-comment" /> 16h</span>
//                                                         </p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </a>
//                                     </div>
//                                     <div className="col-1">
//                                         <div className="notification-options">
//                                             <div className="dropdown text-right">
//                                                 <button className="dropdown-toggle" id="dropdownPostEdit" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                     <span className="fa-stack fa-lg">
//                                                         <i className="far fa-ellipsis-h fa-stack-1x text-dark" />
//                                                     </span>
//                                                 </button>
//                                                 <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownPostEdit">
//                                                     <a className="dropdown-item" href="#/">Mark as unread</a>
//                                                     <a className="dropdown-item" href="#/">Remove this notification</a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="usercard-container">
//                             <div className="usercard" id="profileInfo">
//                                 <div className="row align-items-center no-gutters">
//                                     <div className="col-11">
//                                         <a href="#/" data-toggle="modal" data-target="#notification_modal" className="big-link">
//                                             <div className="media align-items-center">
//                                                 <div className="usercard-picture">
//                                                     <img src="https://nugl-s3.imgix.net/influencers/lil-eazy-e/f8384e77-88c1-493a-b5ef-ca328e7c5458.jpg" alt="nugl_launcher" className="img-round" />
//                                                 </div>
//                                                 <div className="media-body">
//                                                     <div className="usercard-details">
//                                                         <p className="truncate">
//                                                             <b>LiL Eazy E</b> liked your post: "Each profile can make connections and build a network of friends in the NUGL community! Get on #nugl today and make connections just like CJ Melone and E3"
//                               </p>
//                                                         <p>
//                                                             <span className="usercard-info"><i className="text-pink fas fa-heart" /> 1d</span>
//                                                         </p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </a>
//                                     </div>
//                                     <div className="col-1">
//                                         <div className="notification-options">
//                                             <div className="dropdown text-right">
//                                                 <button className="dropdown-toggle" id="dropdownPostEdit" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                     <span className="fa-stack fa-lg">
//                                                         <i className="far fa-ellipsis-h fa-stack-1x text-dark" />
//                                                     </span>
//                                                 </button>
//                                                 <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownPostEdit">
//                                                     <a className="dropdown-item" href="#/">Mark as unread</a>
//                                                     <a className="dropdown-item" href="#/">Remove this notification</a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="usercard-container">
//                             <div className="usercard" id="profileInfo">
//                                 <div className="row align-items-center no-gutters">
//                                     <div className="col-11">
//                                         <a href="#/" data-toggle="modal" data-target="#notification_modal" className="big-link">
//                                             <div className="media align-items-center">
//                                                 <div className="usercard-picture">
//                                                     <img src="https://nugl-s3.imgix.net/brands/hydrofarm-commercial-solutions/d99da35c-a577-4eb3-ba2d-375951b79ef2.jpg" alt="nugl_launcher" className="img-round" />
//                                                 </div>
//                                                 <div className="media-body">
//                                                     <div className="usercard-details">
//                                                         <p className="truncate">
//                                                             <b>Hydrofarm Commercial Solutions</b> liked your post: "Each profile can make connections and build a network of friends in the NUGL community! Get on #nugl today and make connections just like CJ Melone and E3"
//                               </p>
//                                                         <p>
//                                                             <span className="usercard-info"><i className="text-pink fas fa-heart" /> 2d</span>
//                                                         </p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </a>
//                                     </div>
//                                     <div className="col-1">
//                                         <div className="notification-options">
//                                             <div className="dropdown text-right">
//                                                 <button className="dropdown-toggle" id="dropdownPostEdit" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                                                     <span className="fa-stack fa-lg">
//                                                         <i className="far fa-ellipsis-h fa-stack-1x text-dark" />
//                                                     </span>
//                                                 </button>
//                                                 <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownPostEdit">
//                                                     <a className="dropdown-item" href="#/">Mark as unread</a>
//                                                     <a className="dropdown-item" href="#/">Remove this notification</a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <nav aria-label="Page navigation example">
//                     <ul className="pagination justify-content-center mb-2">
//                         <li className="page-item">
//                             <a className="page-link a-med" href="#/" aria-label="Previous">
//                                 <i className="far fa-angle-left" />
//                             </a>
//                         </li>
//                         <li className="page-item active"><a className="page-link a-med" href="#/">1</a></li>
//                         <li className="page-item"><a className="page-link a-med" href="#/">2</a></li>
//                         <li className="page-item">
//                             <a className="page-link a-med" href="#/" aria-label="Next">
//                                 <i className="far fa-angle-right" />
//                             </a>
//                         </li>
//                     </ul>
//                 </nav>
//             </div>
//         );
//     }
// });
