import * as React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { Row, Col, Container, Fade } from 'reactstrap'
import * as routes from '../../constants/routes'
import { AppointmentForm } from './AppointmentForm'
import { Confirmation } from './Confirmation'
import NUGLMDLogo from './nuglmd_logo.svg'
import NUGLMDStep1 from './step-1-login.png'
import NUGLMDStep2 from './step-2-schedule.png'
import NUGLMDStep3 from './step-3-video.png'
import NUGLMDStep4 from './step-4-card.png'

class WebMdComponent extends React.Component {
  render() {
    return (
      <Row noGutters className="text-center">
        <Col
          lg={6}
          className="order-1 d-flex align-items-center bg-gradient nuglmd-left signin-left"
        >
          <Container>
            <Row
              noGutters
              className="no-gutters justify-content-center text-white"
            >
              <Col xs={10} sm={8} md={8} lg={10} xl={8} className="mt-5 mb-5">
                <img
                  src={NUGLMDLogo}
                  alt="nugl md logo"
                  height="70"
                  className="mb-4"
                />
                <h3 className="mb-3">Your doctor. Anywhere. Anytime.</h3>
                <p className="mb-4">
                  <a
                    href="https://www.nugl.com"
                    target="_blank"
                    className="text-white"
                    rel="noopener noreferrer"
                  >
                    <b>NUGL</b>
                  </a>{' '}
                  has teamed up with Dr. Shah of{' '}
                  <a
                    href="https://www.mandalamd.com/"
                    target="_blank"
                    className="text-white"
                    rel="noopener noreferrer"
                  >
                    <b>Mandala</b>
                  </a>{' '}
                  to create NUGL MD – an online telehealth network allowing you
                  to speak with a doctor anywhere, anytime. Utilizing a team of
                  knowledgeable and certified MMJ doctors across every legal
                  state into a single platform, NUGL MD will prove to be the
                  simplest and most cost effective way for patients to get their
                  medical marijuana cards.
                </p>

                <Row className="align-items-top justify-content-center mb-4">
                  <Col sm={3}>
                    <img
                      src={NUGLMDStep1}
                      alt="step-1-login"
                      className="img-fluid"
                      width="200"
                      height="200"
                    />
                    <p>Login with your NUGL account</p>
                  </Col>
                  <Col sm={3}>
                    <img
                      src={NUGLMDStep2}
                      alt="step-2-schedule"
                      className="img-fluid"
                      width="200"
                      height="200"
                    />
                    <p>Schedule an appointment with a doctor</p>
                  </Col>
                  <Col sm={3}>
                    <img
                      src={NUGLMDStep3}
                      alt="step-3-video"
                      className="img-fluid"
                      width="200"
                      height="200"
                    />
                    <p>Video chat with a doctor in your state</p>
                  </Col>
                  <Col sm={3}>
                    <img
                      src={NUGLMDStep4}
                      alt="step-4-card"
                      className="img-fluid"
                      width="200"
                      height="200"
                    />
                    <p>Receive your electronic rec card</p>
                  </Col>
                </Row>
                <a
                  href="https://www.mandalamd.com/nugl_md.html"
                  target="_blank"
                  className="btn btn-pink btn-lg mx-2 mb-3"
                  rel="noopener noreferrer"
                >
                  Learn More
                </a>
                <a
                  href="https://www.nugl.com/"
                  className="btn btn-pink btn-lg mx-2 mb-3"
                >
                  Back to NUGL
                </a>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          lg={6}
          className="order-2 d-flex align-items-center bg-white nuglmd-right signin-right"
        >
          <Container>
            <Row noGutters className="no-gutters justify-content-center">
              <Col xs={10} sm={8} md={8} lg={10} xl={8} className="mt-5 mb-5">
                <Fade>
                  <Switch>
                    <Route
                      path={routes.WEBMD_CONFIRMATION}
                      component={Confirmation}
                    />
                    <Route path={routes.WEBMD} component={AppointmentForm} />
                  </Switch>
                </Fade>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }
}

export const WebMd = withRouter(WebMdComponent)
