import * as React from 'react'
import { Link } from 'react-router-dom'
import { markAsRead } from '../../firebase'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Notification extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      author: {},
      post: {},
      postAuthor: {},
      loaded: false,
    }
  }
  async componentDidMount() {
    try {
      const author = (await this.props.note.data.notifier.get()).data()
      const post = (await this.props.note.post.get()).data()
      const postAuthor = (await post.author.get()).data()
      this.setState({
        author,
        post,
        postAuthor,
        loaded: true,
      })
    } catch (e) {
      console.error(e)
    }
  }
  markAsRead = () => {
    markAsRead(this.props.path)
  }
  render() {
    if (!this.state.loaded) {
      return <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
    }
    return (
      <React.Fragment>
        {/* {this.state.name}
                <b>Marked as {n.read ? "read" : "unread"}. {n.type}</b>                 */}
        <Link
          className={`dropdown-item truncate ${
            this.props.note.read ? 'read' : 'unread'
          }`}
          to={`/${this.state.postAuthor.username}/post/${this.state.post.id}`}
          onClick={this.markAsRead}
        >
          <img
            src={this.state.author.avatar}
            alt="notifier_avatar"
            className="img-round mr-1"
          />
          <span className="medium">
            <b>{this.state.author.name || this.state.author.username}</b>{' '}
            {this.props.note.type === 'like' ? 'liked' : 'commented on'} your{' '}
            {this.state.post.text ? (
              <span>
                post:{' '}
                <span>
                  <em>"{this.state.post.text}"</em>
                </span>
              </span>
            ) : (
              <span>image.</span>
            )}
          </span>
        </Link>
      </React.Fragment>
    )
  }
}

export { Notification }
