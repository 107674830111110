import * as React from 'react'

class ProfileName extends React.PureComponent {
  render() {
    return (
      <div>
        <h5 className="card-title mb-0 truncate">
          {this.props.name ? this.props.name : this.props.username}
        </h5>
        <p className="mb-0 truncate">
          <a href="#/" className="gray-link">
            @{this.props.username}
          </a>
        </p>
      </div>
    )
  }
}

export { ProfileName }
