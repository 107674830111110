import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from 'reactstrap'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { notificationSub } from '../../firebase'
import NotificationsModal from './NotificationsModal'

class NotificationsLink extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      notifications: [],
      unread: 0,
    }
  }
  async componentDidMount() {
    this.unsubscribe = await notificationSub(this.props.user).onSnapshot(
      async snapshot => {
        // if (!snapshot.exists) {
        //     return;
        // }
        const unread = snapshot.docs.filter(d => !d.data().read).length
        this.setState({
          notifications: snapshot.docs.map(d => ({ id: d.id, ...d.data() })),
          unread,
        })
      },
    )
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    return (
      <React.Fragment>
        <FontAwesomeIcon
          icon={faBell}
          className="mr-2"
          id="notificationsButton"
          size="lg"
        />
        {this.props.platform === 'desktop' && (
          <React.Fragment>
            <span className="mobile-hide">Notifications</span>
          </React.Fragment>
        )}{' '}
        {this.state.unread !== 0 && (
          <Badge color="pink" pill className="badge-notification" style={{}}>
            {this.state.unread}
          </Badge>
        )}
        <NotificationsModal
          toggle={this.props.toggle}
          open={this.props.open}
          user={this.props.user}
          notifications={this.state.notifications}
        />
      </React.Fragment>
    )
  }
}

export default NotificationsLink
