import * as React from 'react'
import { firebase } from '../../firebase'

import { ProfileName } from './ProfileName'
import { ProfileEdit } from './ProfileEdit'
import { ProfileHeadline } from './ProfileHeadline'
import { ProfileBusinessType } from './ProfileBusinessType'
import { ProfileLocation } from './ProfileLocation'
import { ProfileDetails } from './ProfileDetails'
import { BusinessRating } from './BusinessRating'

class BusinessProfile extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      profile: {
        id: '',
        editorIDs: [],
        name: '',
        username: '',
        rating: 0,
        headline: '',
        types: [],
        details: '',
        location: {
          city: 'Profile',
          state: 'NUGL',
        },
      },
    }
  }
  async componentDidMount() {
    if (this.props.isBusiness) {
      this.unsubscribe = await this.props.profile.businessRef.onSnapshot(
        async snapshot => {
          this.setState({ profile: snapshot.data() })
        },
      )
    } else {
      this.unsubscribe = await firebase.firestore
        .doc(`people/${this.props.profile.id}`)
        .onSnapshot(async snapshot => {
          this.setState({ profile: snapshot.data() })
        })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    const editable = this.props.isBusiness
      ? this.props.profile.editorIDs.includes(this.props.user.id)
      : this.props.profile.id === this.props.user.id
    return (
      <div className="card mb-2">
        <div className="card-body profile-info">
          {editable && (
            <ProfileEdit
              data={this.props.profile}
              user={this.props.user}
              id={this.props.profile.id}
            />
          )}

          <ProfileName
            name={this.state.profile.name}
            username={this.state.profile.username}
          />

          <div className="dashboard-nav">
            <ul className="nav flex-column mb-0">
              {this.props.isBusiness && this.state.profile.rating && (
                <BusinessRating rating={this.state.profile.rating} />
              )}
              <ProfileHeadline headline={this.state.profile.headline} />
              {this.state.isBusiness && (
                <ProfileBusinessType types={this.state.profile.typeStrings} />
              )}
              <ProfileLocation
                city={this.state.profile.location.city}
                state={this.state.profile.location.state}
              />
            </ul>
          </div>

          <ProfileDetails details={this.state.profile.details} />
        </div>
      </div>
    )
  }
}

export { BusinessProfile }
