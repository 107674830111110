const INITIAL_STATE = {
  id: '',
}
export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_USER': {
      return action.user
    }
    default:
      return state
  }
}
