import * as React from 'react'
import { Button, Form, FormGroup, Input } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'

import {
  faCamera,
  faShareSquare,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'

import { firebase } from '../../firebase'
import { uuid } from '../../functions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class CreatePostU extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedImage: '',
      filename: '',
      isUploading: false,
      postId: uuid.generate(),
      progress: null,
      error: null,
      text: '',
      user: props.user,
    }
  }

  createPostId = () => this.setState({ postId: uuid.generate() })

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 })
  handleProgress = progress => this.setState({ progress })
  handleUploadError = error =>
    this.setState({ isUploading: false, error: true }) && console.error(error)
  handleUploadSuccess = filename => {
    this.setState({ filename, progress: 100, isUploading: false })
    firebase.storage
      .ref(`posts/${this.state.postId}/images`)
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ uploadedImage: url }))
  }
  handleClear = () =>
    this.setState({
      uploadedImage: '',
      filename: '',
      isUploading: false,
      progress: null,
      error: null,
    })

  updateText = event => {
    if (event.target.name === 'text') {
      this.setState({
        text: event.target.value,
      })
    }
  }

  submitForm = async e => {
    e.preventDefault()
    this.setState({ loading: true })
    const timestamp = Date.now()
    const date = new Date()
    // Save to posts/userId/post. As in this is the feed for a profile
    await firebase.firestore
      .doc(`feeds/${this.props.feedId}/posts/${this.state.postId}`)
      .set(
        {
          author: await firebase.firestore.doc(`people/${this.props.user.id}`),
          image: this.state.uploadedImage,
          withImage: !!this.state.uploadedImage,
          filename: this.state.filename,
          text: this.state.text,
          timestamp,
          date,
          id: this.state.postId,
          ref: firebase.firestore.doc(
            `users/${this.props.user.id}/posts/${this.state.postId}`,
          ),
        },
        { merge: true },
      )

    // Save to users/userId/post. As in this is a post this user made
    await firebase.firestore
      .doc(`users/${this.props.user.id}/posts/${this.state.postId}`)
      .set({
        ref: firebase.firestore.doc(
          `feeds/${this.props.feedId}/posts/${this.state.postId}`,
        ),
        timestamp,
      })

    this.setState({
      uploadedImage: '',
      filename: '',
      isUploading: false,
      postId: uuid.generate(),
      progress: null,
      error: null,
      text: '',
    })
  }

  render() {
    if (!this.props.user) {
      return <div />
    }
    return (
      <div className="card post-input bg-lightgray mb-2">
        <div className="card-body">
          <div className="media">
            <img
              src={this.state.user.avatar}
              alt="author"
              className="rounded-circle img-fluid mr-2"
            />
            <div className="media-body">
              <Form onSubmit={this.submitForm}>
                <FormGroup className="input-group-sm">
                  <Input
                    value={this.state.text}
                    onChange={this.updateText}
                    type="textarea"
                    name="text"
                    id="exampleText"
                    className="form-control expand"
                    rows={1}
                    placeholder="What's on your mind?"
                  />
                </FormGroup>
                {this.state.filename && (
                  <img
                    src={this.state.uploadedImage}
                    alt={this.state.filename}
                    title={this.state.filename}
                    className="img-fluid"
                    style={{
                      height: 'auto',
                      width: 'auto',
                      maxHeight: '10em',
                    }}
                  />
                )}
                <div className="image-upload">
                  {!this.state.filename && (
                    <CustomUploadButton
                      accept="image/*"
                      disabled={this.state.progress}
                      name="avatar"
                      filename={file => uuid.generate()}
                      storageRef={firebase.storage.ref(
                        `posts/${this.state.postId}/images`,
                      )}
                      onUploadStart={this.handleUploadStart}
                      onUploadError={this.handleUploadError}
                      onUploadSuccess={this.handleUploadSuccess}
                      onProgress={this.handleProgress}
                      style={{
                        cursor: 'pointer',
                      }}
                      className="btn btn-link"
                    >
                      <FontAwesomeIcon icon={faCamera} /> Add photo{' '}
                      {this.state.progress && (
                        <span>{this.state.progress}% Uploaded</span>
                      )}
                    </CustomUploadButton>
                  )}
                  {this.state.filename && (
                    <Button
                      color="#3333FF"
                      onClick={this.handleClear}
                      style={{ backgroundColor: 'rgb(0,0,0,0)' }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Remove photo
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!this.state.text && !this.state.uploadedImage}
                    className="btn btn-pink float-right"
                  >
                    <FontAwesomeIcon icon={faShareSquare} /> Post
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const CreatePost = withRouter(connect(mapStateToProps)(CreatePostU))
