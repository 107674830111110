import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/pro-regular-svg-icons'
import { faCircle, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'

class FollowingBlock extends React.Component {
  render() {
    const { profile, people } = this.props
    return (
      <React.Fragment>
        <div id="suggested-card" className="card mb-2">
          <div className="card-body">
            <h5 className="card-title">
              <span className="fa-stack">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="fa-stack-2x text-darkgray"
                />
                <FontAwesomeIcon
                  icon={faUserFriends}
                  className="fa-stack-1x fa-inverse"
                />
              </span>{' '}
              Following
            </h5>
            {(!profile || !people) && <FontAwesomeIcon icon={faSpinner} spin />}
            {profile &&
              people &&
              people.slice(0, 3).map(p => {
                return (
                  <div key={p.id} className="usercard-container">
                    <div className="usercard" id="profileInfo">
                      <div className="media align-items-center">
                        <div className="usercard-picture">
                          <Link to={`/${p.username}`}>
                            <img
                              src={p.avatar}
                              alt="user_sample_01"
                              className="img-round"
                            />
                          </Link>
                        </div>
                        <div className="media-body">
                          <div className="close">
                            <button
                              data-toggle="tooltip"
                              data-placement="top"
                              data-original-title="Dismiss"
                            >
                              <i className="fal fa-times" />
                            </button>
                          </div>
                          <div className="usercard-details">
                            <p className="truncate">
                              <Link
                                to={`/${p.username}`}
                                className="usercard-title"
                              >
                                {p.name || p.username}
                              </Link>
                            </p>
                            <p className="truncate">
                              <span className="usercard-info">
                                {p.headline}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
          {profile && people && (
            <div className="card-footer">
              <Link to={`/${profile.username}/following`}>
                <i className="far fa-users mr-1" />
                View all
              </Link>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export { FollowingBlock }
