import * as React from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import { firebase } from '../../firebase'

/**
 * props
 * path: path to notification in firestore
 * notification: actual notification data object
 * open: whether to have the dropdown open or not
 * toggle: open / close dropdown
 */
class NotificationDropdown extends React.PureComponent {
  markRead = async () => {
    await firebase.firestore
      .doc(this.props.path)
      .set({ read: !this.props.notification.read }, { merge: true })
  }
  remove = async () => {
    await firebase.firestore.doc(this.props.path).delete()
  }
  render() {
    if (!this.props.notification) {
      return null
    }
    return (
      <Dropdown isOpen={this.props.open} toggle={this.props.toggle}>
        <DropdownToggle
          style={{ backgroundColor: 'rgb(0,0,0,0)', border: 'none' }}
        >
          <FontAwesomeIcon color="#000" icon={faEllipsisH} size="lg" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={this.markRead}>
            Mark as {!this.props.notification.read ? 'read' : 'unread'}
          </DropdownItem>
          <DropdownItem onClick={this.remove}>
            Remove this notification
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export { NotificationDropdown }
