import { library } from '@fortawesome/fontawesome-svg-core'

// Regular icons
import {
  faBell as farBell,
  faBriefcase as farBriefcase,
  faChartBar as farChartBar,
  faCircleNotch as farCircleNotch,
  faComment as farComment,
  faComments as farComments,
  faEdit as farEdit,
  faEnvelope as farEnvelope,
  faExternalLink as farExternalLink,
  faFlag as farFlag,
  faHeart as farHeart,
  faHome as farHome,
  faInfoCircle as farInfoCircle,
  faPencil as farPencil,
  faPlus as farPlus,
  faSearch as farSearch,
  faSignIn as farSignIn,
  faSpinner as farSpinner,
  faTimes as farTimes,
  faTrash as farTrash,
  faUser as farUser,
  faUserCircle as farUserCircle,
  faUserPlus as farUserPlus,
} from '@fortawesome/pro-regular-svg-icons'

// Solid icons
import {
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faHeart as fasHeart,
  faHome as fasHome,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  farBell,
  farBriefcase,
  farChartBar,
  farCircleNotch,
  farComment,
  farComments,
  farEdit,
  farEnvelope,
  farExternalLink,
  farFlag,
  farHeart,
  farHome,
  farInfoCircle,
  farPencil,
  farPlus,
  farSearch,
  farSignIn,
  farSpinner,
  farTimes,
  farTrash,
  farUser,
  farUserCircle,
  farUserPlus,
  fasAngleLeft,
  fasAngleRight,
  fasChevronDown,
  fasChevronUp,
  fasCircle,
  fasHeart,
  fasHome,
)
