import * as React from 'react'
import { withRouter } from 'react-router'

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, connectHits } from 'react-instantsearch-dom'
import 'instantsearch.css/themes/reset.css'

import { SearchBox } from './SearchBox'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)

const parseHits = (hits, recordType) => {
  const returnHits = hits
    .filter(h => (h.name || h.username) && h.record_type === recordType)
    .map(hit => (
      <Hit key={`search-result-${hit.username}-${hit.id}`} hit={hit} />
    ))
    .slice(0, 4)

  return returnHits.length === 0 ? null : (
    <React.Fragment>
      <DropdownItem header>
        <span className="medium">
          {recordType.charAt(0).toUpperCase() + recordType.slice(1)}
        </span>
      </DropdownItem>
      <div className="dropdown-suggestions">{returnHits}</div>
      <DropdownItem divider />
    </React.Fragment>
  )
}

const PeopleHits = ({ hits }) => {
  return parseHits(hits, 'people')
}
const BusinessHits = ({ hits }) => {
  return parseHits(hits, 'businesses')
}

const PeopleResults = connectHits(PeopleHits)
const BusinessResults = connectHits(BusinessHits)

class NavbarSearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
      loading: false,
      searchTerm: '',
    }
  }

  toggle = () => this.setState(p => ({ dropdown: !p.dropdown }))

  handleChange = searchTerm => this.setState({ searchTerm: searchTerm })

  open = () => this.setState({ dropdown: true })

  saveSearch = () => {
    let index = parseInt(localStorage.getItem('nugl.search.index'), 10) || 0
    const recentSearches = Array.from({ length: 4 }, (x, i) => i).map(i =>
      localStorage.getItem(`nugl.search.${i}`),
    )

    if (
      !recentSearches.includes(this.state.searchTerm) &&
      this.state.searchTerm !== ''
    ) {
      index = (index + 1) % 4
      localStorage.setItem(`nugl.search.${index}`, this.state.searchTerm)
      localStorage.setItem('nugl.search.index', index)
    }
  }

  delete = e => {
    for (let i = 0; i < 4; i++) {
      localStorage.removeItem(`nugl.search.${i}`)
    }
    this.forceUpdate()
  }

  render() {
    if (this.props.location.pathname === '/search') {
      return null
    }

    const containerClass =
      this.props.platform === 'desktop'
        ? 'search-container'
        : 'search-container d-block d-sm-block d-md-none'
    const recentSearches = Array.from({ length: 4 }, (x, i) => i)
      .map(i => localStorage.getItem(`nugl.search.${i}`))
      .filter(search => search !== null)
      .filter((el, p, a) => a.indexOf(el) === p)
      .map((search, i) => (
        <DropdownItem
          key={`nugl.search.${i}`}
          onClick={() => this.setState({ searchTerm: search })}
          toggle={false}
        >
          {search}
        </DropdownItem>
      ))

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_INDICES_USERS}
      >
        <div className={containerClass} role="search">
          <SearchBox
            placeholder="Search NUGL"
            defaultRefinement={this.state.searchTerm}
            onChange={this.handleChange}
            open={this.open}
            search={this.saveSearch}
          />
        </div>
        <Dropdown
          className="search-dropdown"
          isOpen={this.state.dropdown}
          toggle={this.toggle}
        >
          <DropdownToggle tag="span" />
          <DropdownMenu className="search-dropdown-menu">
            <div className="dropdown-caret">
              <span className="caret-outer" />
              <span className="caret-inner" />
            </div>
            <DropdownItem header>
              <span className="medium clearfix">
                <span className="float-left">Recent searches</span>{' '}
                <a className="float-right ml-4" href="#/" onClick={this.delete}>
                  Clear history
                </a>
              </span>
            </DropdownItem>
            <div className="dropdown-suggestions">{recentSearches}</div>
            {this.state.searchTerm && (
              <React.Fragment>
                <DropdownItem divider />
                <PeopleResults />
                <BusinessResults />
                <DropdownItem
                  onClick={() => {
                    const searchTerm = this.state.searchTerm
                    this.props.history.push({
                      pathname: '/search',
                      state: { searchTerm },
                    })
                  }}
                >
                  See all results for <b>{this.state.searchTerm}</b>
                </DropdownItem>
              </React.Fragment>
            )}
          </DropdownMenu>
        </Dropdown>
      </InstantSearch>
    )
  }
}

function HitU(props) {
  return (
    <DropdownItem href={`/${props.hit.username}`}>
      <img className="img-round mr-1" alt="" src={props.hit.avatar} />
      <b>{props.hit.name || props.hit.username}</b>{' '}
      <span className="text-darkgray">
        {props.hit.headline.length > 32
          ? props.hit.headline.slice(0, 32) + '...'
          : props.hit.headline}
      </span>
    </DropdownItem>
  )
}

const Hit = withRouter(HitU)

export default withRouter(NavbarSearch)
