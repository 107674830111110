import * as React from 'react'
import { Dropdown } from 'reactstrap'
import { markAllNotificationsAsRead } from '../../firebase'
import { Link } from 'react-router-dom'

import { Notification } from './Notification'

class NotificationsModal extends React.PureComponent {
  markAllAsRead = () => {
    markAllNotificationsAsRead(this.props.user)
  }
  render() {
    if (!this.props.open) {
      return null
    }
    return (
      <React.Fragment>
        <Dropdown
          toggle={this.props.toggle}
          placement="auto"
          isOpen={this.props.open}
          target="notificationsPopover"
          className="dropdown-menu notifications"
        >
          <div className="dropdown-header">
            <div className="medium">
              Notifications
              <span className="float-right">
                <a href="#/" onClick={this.markAllAsRead}>
                  Mark all as read
                </a>
              </span>
            </div>
          </div>
          <div className="dropdown-notifications" style={{ minWidth: '15em' }}>
            {this.props.notifications.length !== 0 &&
              this.props.notifications
                .sort((l, r) => r.data.timestamp - l.data.timestamp)
                .slice(0, 5)
                .map((n, i) => (
                  <React.Fragment key={`notif-${n.id}`}>
                    {/* {i !== 0 && <hr />} */}
                    <Notification
                      note={n}
                      path={`notifications/${this.props.user}/queue/${n.id}`}
                    />
                    {/* <br /> */}
                  </React.Fragment>
                ))}
            {this.props.notifications.length === 0 && (
              <div style={{ padding: '1em' }}>No notifications yet!</div>
            )}
          </div>
          <div className="dropdown-divider" />
          <Link className="dropdown-item" to={'/home/notifications'}>
            See all notifications
          </Link>
        </Dropdown>
      </React.Fragment>
    )
  }
}

export default NotificationsModal
