import * as React from 'react'
import { withRouter } from 'react-router'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import { auth } from '../../firebase'
import NUGLCross from './ico_cross.svg'

class LogIn extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      username: '',
    }
  }
  change = type => e => {
    const value = e.target.value
    this.setState({
      [type]: value,
    })
  }
  submit = async e => {
    try {
      await auth.doSignInWithEmailAndPassword(
        this.state.username,
        this.state.password,
      )
      this.props.history.push('/home')
    } catch (err) {
      this.setState({ error: err.message })
    }
  }
  navigate = route => () => this.props.history.push('/' + route)

  render() {
    return (
      <React.Fragment>
        <img src={NUGLCross} alt="nugl cross" height="80" className="mb-3" />
        <b style={{ verticalAlign: 'top', fontWeight: 900, marginLeft: -15 }}>
          v2
        </b>
        <h4 className="mb-3">Log in to NUGL</h4>
        <Form className="mb-5">
          <FormGroup>
            <Label>Email address</Label>
            <Input
              type="email"
              value={this.state.username}
              onChange={this.change('username')}
            />
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="password"
              value={this.state.password}
              onChange={this.change('password')}
            />
          </FormGroup>
          <br />
          <Button
            onClick={this.submit}
            disabled={!(this.state.username && this.state.password)}
            className="btn-pink btn-block"
          >
            Log In
          </Button>
          <Button
            onClick={this.navigate('signup')}
            className="btn-outline btn-block"
          >
            Create an account
          </Button>
          {this.state.error}
        </Form>
      </React.Fragment>
    )
  }
}

export const LogInForm = withRouter(LogIn)
