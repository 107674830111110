import * as React from 'react'
import { firebase } from '../../firebase'

import { faPen, faCamera } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from '../shared/IconButton'
import {
  Button,
  Container,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'

class ProfileEdit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false,
      modal: false,
      businessOptions: [],
      isUploading: false,
      location: {},
      ...props.data,
    }
    this.toggleTooltip = this.toggleTooltip.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  async componentWillMount() {
    const db = (await firebase.firestore
      .collection('types/business/categories')
      .get()).docs

    db.forEach(doc => {
      const data = doc.data()

      this.state.businessOptions.push(
        <option key={doc.id} name={doc.id}>
          {data.string}
        </option>,
      )
    })

    this.unsubscribe = await firebase.firestore
      .doc(`people/${this.props.user.id}`)
      .onSnapshot(snapshot => {
        this.setState({
          ...snapshot.data(),
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  toggleTooltip = () => {
    this.setState(previousState => ({
      tooltipOpen: !previousState.tooltipOpen,
    }))
  }

  toggleModal = () => {
    this.setState(previousState => ({
      modal: !previousState.modal,
    }))
  }

  handleUploadStart = () => this.setState({ isUploading: true })

  handleUploadError = error =>
    this.setState({ isUploading: false }) && console.error(error)

  handleUploadSuccess = async filename => {
    let key = filename.split('.')[0]
    const url = await firebase.storage
      .ref(`profiles/${this.state.id}/images`)
      .child(filename)
      .getDownloadURL()

    this.setState({
      [key]: url,
      filename,
      isUploading: false,
    })
  }

  change = type => ev => {
    const value = ev.target.value
    if (['city', 'state'].includes(type)) {
      this.setState(p => ({
        location: {
          ...p.location,
          [type]: value,
        },
      }))
      return
    }
    this.setState({
      [type]: value,
    })
  }

  isDefinedInObject = obj => key => !!!obj[key]

  onlyDefined = state => {
    const DEFAULT_USER = [
      'avatar',
      'cover',
      'details',
      'headline',
      'location',
      'name',
      'rating',
    ]
    const returnObject = {}
    DEFAULT_USER.forEach(du =>
      state[du] ? (returnObject[du] = state[du]) : null,
    )
    return returnObject
  }

  handleSubmit = async e => {
    const data = {
      ...this.onlyDefined(this.state),
    }

    await firebase.firestore
      .doc(`people/${this.props.id}`)
      .set(data, { merge: true })

    this.toggleModal()
  }

  render() {
    return (
      <React.Fragment>
        <div className="profile-info-edit" id="profile-edit-button">
          <span onClick={this.toggleModal}>
            <IconButton icon={faPen} />
          </span>
          <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            target="profile-edit-button"
            toggle={this.toggleTooltip}
          >
            Edit profile info
          </Tooltip>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Edit profile</ModalHeader>
          <ModalBody>
            <div
              className="profile-cover"
              style={{
                backgroundImage: `url(${this.state.cover})`,
                height: '200px',
              }}
            >
              <div className="profile-cover-edit">
                <CustomUploadButton
                  accept="image/*"
                  name="cover"
                  filename={file => 'cover'}
                  storageRef={firebase.storage.ref(
                    `profiles/${this.props.id}/images`,
                  )}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                >
                  <IconButton icon={faCamera} />
                </CustomUploadButton>
              </div>
            </div>
            <Container style={{ position: 'relative', marginBottom: '100px' }}>
              <div className="profile-picture-wrapper">
                <div className="profile-picture smaller">
                  <img src={this.state.avatar} alt="" />
                </div>
                <div className="profile-picture-edit">
                  <CustomUploadButton
                    accept="image/*"
                    name="avatar"
                    filename={file => 'avatar'}
                    storageRef={firebase.storage.ref(
                      `profiles/${this.props.id}/images`,
                    )}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                  >
                    <IconButton icon={faCamera} />
                  </CustomUploadButton>
                </div>
              </div>
            </Container>

            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  onChange={this.change('name')}
                  type="text"
                  name="name"
                  value={this.state.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="headline">Headline</Label>
                <Input
                  onChange={this.change('headline')}
                  type="text"
                  name="headline"
                  value={this.state.headline}
                />
              </FormGroup>
              <FormGroup>
                <Label for="details">About</Label>
                <Input
                  onChange={this.change('details')}
                  type="textarea"
                  name="details"
                  rows={4}
                  value={this.state.details}
                />
              </FormGroup>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="city">City</Label>
                    <Input
                      onChange={this.change('city')}
                      type="text"
                      name="city"
                      value={this.state.location.city}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="state">State</Label>
                    <Input
                      onChange={this.change('state')}
                      type="text"
                      name="state"
                      value={this.state.location.state}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter style={flexOverride}>
            <Button className="btn btn-pink" onClick={this.handleSubmit}>
              Save
            </Button>
            <Button className="btn btn-outline" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

const flexOverride = {
  justifyContent: 'flex-start',
}

export { ProfileEdit }
