import * as React from 'react'

class ProfileHeadline extends React.PureComponent {
  render() {
    return (
      <li className="nav-item">
        <div href="#/" className="nav-link">
          {this.props.headline}
        </div>
      </li>
    )
  }
}

export { ProfileHeadline }
