import * as React from 'react'

import { BlockAd } from '../Advertisement'
import { Footer } from './Footer'
import { Navigation } from './Navigation'
import { SuggestedProfiles } from './SuggestedProfiles'

class LeftColumn extends React.Component {
  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  render() {
    const { section, profile, badgeCount } = this.props
    return (
      <div className="sticky-sidebar">
        <div className="sticky-sidebar-inner">
          <div className="card mb-2">
            <Navigation
              section={section}
              profile={profile}
              badgeCount={badgeCount}
            />
          </div>
          <div>
            <div className="mb-2 d-none d-sm-none d-md-block d-lg-block d-xl-none">
              <BlockAd />
            </div>
            <div
              id="suggested-card"
              className="card mb-2 d-none d-sm-none d-md-block d-lg-block d-xl-none"
            >
              <SuggestedProfiles />
            </div>
            <div className="d-block">
              <BlockAd />
            </div>
            <div className="d-block d-sm-block d-md-block d-lg-block d-xl-none">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { LeftColumn }
