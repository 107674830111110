/* tslint:disable:ordered-imports */

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { App } from './components/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/simple-sidebar.css'
import './assets/css/index.css'
// import unregister from "./registerServiceWorker";
import store from './store'

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
// unregister();
