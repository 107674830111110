import * as React from 'react'
import { connect } from 'react-redux'
import { firebase } from '../../firebase'

export const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { onSetAuthUser } = this.props

      firebase.auth.onAuthStateChanged(authUser => {
        authUser ? onSetAuthUser(authUser) : onSetAuthUser(null)
      })
    }

    render() {
      return <Component />
    }
  }

  const mapDispatchToProps = dispatch => ({
    onSetAuthUser: async authUser => {
      dispatch({ type: 'AUTH_USER_SET', authUser })
      if (!authUser) {
        return
      }
      const userRef = (await (await firebase.firestore.doc(
        `users/${authUser.uid}`,
      )).get()).data()
      dispatch({ type: 'SET_USER', user: userRef })
    },
  })

  return connect(
    null,
    mapDispatchToProps,
  )(WithAuthentication)
}
