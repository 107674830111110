import * as React from 'react'
import { withRouter } from 'react-router-dom'

class ConfirmationComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h4 className="mb-3">Your appointment request has been submitted.</h4>
        <p>
          A medical expert from will be in touch shortly to confirm your
          doctor's availability.
        </p>
      </React.Fragment>
    )
  }
}

export const Confirmation = withRouter(ConfirmationComponent)
