import * as React from 'react'
import { Link, withRouter, Route, Switch } from 'react-router-dom'
import { Card, CardBody, CardText, Row, Col, Container, Fade } from 'reactstrap'
import * as routes from '../../constants/routes'
import { LogInForm } from './LogInForm'
import { SignUpForm } from './SignUpForm'
import { SignNavigate } from './SignNavigate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faBriefcase,
  faComment,
} from '@fortawesome/free-solid-svg-icons'

class SignUpComponent extends React.Component {
  render() {
    return (
      <Row noGutters>
        <Col
          md={6}
          className="order-2 order-md-1 d-flex align-items-center bg-gradient signin-left"
        >
          <Container>
            <Row
              noGutters
              className="ow no-gutters justify-content-center text-white"
            >
              <Col sm={8} md={10} lg={8} xl={6} className="mt-5 mb-5">
                <p className="mb-1 mb-md-2">
                  <FontAwesomeIcon icon={faSearch} className="fa-lg mr-2" />
                  Find people, places, &amp; products nearby
                </p>
                <p className="mb-1 mb-md-2">
                  <FontAwesomeIcon icon={faBriefcase} className="fa-lg mr-2" />
                  Add a business profile and menu
                </p>
                <p className="mb-0">
                  <FontAwesomeIcon icon={faComment} className="fa-lg mr-2" />{' '}
                  Chatter with friends and colleagues
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          md={6}
          className="order-1 order-md-2 d-flex align-items-center bg-white signin-right"
        >
          <Container>
            <Row noGutters className="row no-gutters justify-content-center">
              <Col sm={8} md={10} lg={8} xl={6} className="mt-5 mb-5">
                <Fade>
                  <Switch>
                    <Route path="/signup" component={SignUpForm} />
                    <Route path="/login" component={LogInForm} />
                    <Route component={SignNavigate} />
                  </Switch>
                </Fade>
                <div className="text-center">
                  <a href="https://app.nugl.com/">Version 1.0</a>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    )
  }
}

export const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>
)
export const LoginLink = () => (
  <Card className="signup-card">
    <CardBody>
      <CardText>
        Already have an account? <Link to={routes.SIGN_IN}>Sign In ></Link>
      </CardText>
      <CardText color="muted">
        For now, your NUGL account is not the same as an account on
        app.nugl.com.
      </CardText>
    </CardBody>
  </Card>
)
export const SignUp = withRouter(SignUpComponent)
