import * as React from 'react'
import { Input, Button } from 'reactstrap'

class EditText extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
    }
  }
  onChange = event => {
    const text = event.target.value
    this.setState({
      text,
    })
  }
  update = () => {
    this.props.update(this.state.text)
  }
  render() {
    return (
      <React.Fragment>
        <Input
          value={this.state.text}
          onChange={this.onChange}
          type="textarea"
          name="text"
          id="exampleText"
          className="form-control"
          rows={3}
          placeholder="What's on your mind?"
        />
        <div style={{ textAlign: 'right', margin: '1em 0' }}>
          <Button onClick={this.update} className="btn-pink">
            Save
          </Button>
        </div>
      </React.Fragment>
    )
  }
}

export { EditText }
