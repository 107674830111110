import * as React from 'react'
import { NotificationDropdown } from './NotificationDropdown'
import { Link } from 'react-router-dom'
import { markAsRead } from '../..//firebase'

class Notification extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      authorImg: '',
      optionsOpen: false,
      postText: '',
    }
  }

  async componentDidMount() {
    try {
      if (this.props.notification.post) {
        const post = (await this.props.notification.post.get()).data()
        const author = (await post.author.get()).data()
        this.setState({
          postText: post.text,
          postAuthorUsername: author.username,
        })
      }
      if (this.props.notification.data.notifier) {
        // TODO: where is notifier supposed to come from? it's null here sometimes and error is caught! WHY??
        const notifier = (await this.props.notification.data.notifier.get()).data()
        this.setState({
          authorImg: notifier.avatar,
          authorName: notifier.name || notifier.username,
          username: notifier.username,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  markAsRead = () => {
    markAsRead(this.props.path)
  }

  toggle = () => {
    this.setState(p => ({ optionsOpen: !p.optionsOpen }))
  }

  render() {
    return (
      <div
        className={`usercard-container ${
          this.props.notification.read ? 'read' : 'unread'
        }`}
      >
        <div className="usercard" id="profileInfo">
          <div className="row align-items-center no-gutters">
            <div className="col-11">
              <Link
                href="#/"
                data-toggle="modal"
                data-target="#notification_modal"
                className="big-link"
                to={`/${this.state.postAuthorUsername}/post/${
                  this.props.notification.post.id
                }`}
                onClick={this.markAsRead}
              >
                <div className="media align-items-center">
                  <div className="usercard-picture">
                    {this.state.authorImg && (
                      <img
                        src={this.state.authorImg}
                        alt="notifier_avatar"
                        className="img-round rounded-circle"
                      />
                    )}
                  </div>
                  <div className="media-body">
                    <div className="usercard-details">
                      <p className="truncate">
                        <b>{this.state.authorName}</b>{' '}
                        {this.props.notification.type === 'like'
                          ? 'liked'
                          : 'commented on'}{' '}
                        your{' '}
                        {this.props.notification.data.text ? (
                          <span>
                            post:{' '}
                            <span>
                              <em>"{this.state.postText}"</em>
                            </span>
                          </span>
                        ) : (
                          <span>post.</span>
                        )}
                      </p>
                      <p>
                        <span className="usercard-info">
                          <i className="text-pink fas fa-heart" /> 13h
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-1">
              <div className="notification-options">
                <NotificationDropdown
                  open={this.state.optionsOpen}
                  toggle={this.toggle}
                  notification={this.props.notification}
                  path={this.props.path}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { Notification }
