import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faEyeSlash,
  faEdit,
  faFlag,
} from '@fortawesome/pro-regular-svg-icons'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'

class CommentOptions extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      dropdown: false,
    }
  }

  toggle = () => this.setState(p => ({ dropdown: !p.dropdown }))

  render() {
    return (
      <div className="comment-options">
        <Dropdown isOpen={this.state.dropdown} toggle={this.toggle}>
          <DropdownToggle tag="a" className="comment-options-toggle">
            <FontAwesomeIcon
              icon={faEllipsisH}
              size="lg"
              className="comment-options-ellipsis text-gray"
            />
          </DropdownToggle>
          <DropdownMenu right className="comment-options-dropdown">
            <DropdownItem disabled>
              <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
              Hide this comment
            </DropdownItem>
            <DropdownItem disabled>
              <FontAwesomeIcon icon={faFlag} className="mr-2" />
              Report this comment
            </DropdownItem>
            {this.props.author && (
              <React.Fragment>
                <DropdownItem divider />
                <DropdownItem onClick={this.props.edit}>
                  <FontAwesomeIcon icon={faEdit} className="mr-2" />
                  Edit comment
                </DropdownItem>
                <DropdownItem onClick={this.props.delete}>
                  <FontAwesomeIcon icon={faTrash} className="mr-2" />
                  Delete comment
                </DropdownItem>
              </React.Fragment>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export { CommentOptions }
