import * as React from 'react'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Tooltip,
} from 'reactstrap'

import '../shared/Icons'
import AccountDropdown from './AccountDropdown'
import NUGLCross from './nugl_cross.svg'
import NavbarSearch from './NavbarSearch'
import NotificationsLink from './NotificationsLink'
import { FeedbackFormModal } from '../FeedbackFormModal'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as routes from '../../constants/routes'

class WideWebNavigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleFeedback: false,
      toggleNotifications: false,
      toggleOpen: false,
      toggleAboutTooltip: false,
      toggleHomeTooltip: false,
      toggleNotificationsTooltip: false,
      toggleReportTooltip: false,
    }
  }

  componentDidMount() {
    if (this.shouldHideNavigation()) {
      document.body.classList.add('without-nav')
    }
  }

  shouldHideNavigation = () =>
    [routes.WEBMD, routes.WEBMD_CONFIRMATION].indexOf(
      this.props.location.pathname.toLowerCase(),
    ) > -1

  leaveFeedback = () => {
    this.setState(pre => ({ toggleFeedback: !!!pre.toggleFeedback }))
  }

  modalNotifications = () => {
    this.setState(pre => ({ toggleNotifications: !pre.toggleNotifications }))
  }

  toggleAboutTooltip = () => {
    this.setState(previousState => ({
      tooltipAboutOpen: !previousState.tooltipAboutOpen,
    }))
  }
  toggleHomeTooltip = () => {
    this.setState(previousState => ({
      tooltipHomeOpen: !previousState.tooltipHomeOpen,
    }))
  }
  toggleNotificationsTooltip = () => {
    this.setState(previousState => ({
      tooltipNotificationsOpen: !previousState.tooltipNotificationsOpen,
    }))
  }
  toggleReportTooltip = () => {
    this.setState(previousState => ({
      tooltipReportOpen: !previousState.tooltipReportOpen,
    }))
  }

  render() {
    if (this.shouldHideNavigation()) {
      return null
    }
    return (
      <Navbar dark expand="md" fixed="top" className="nugl-nav">
        <Container>
          <Nav className="nugl-topbar nugl-topbar-left right-actions d-block d-sm-block d-md-none">
            <NavItem>
              <AccountDropdown
                platform="mobile"
                isOpen={this.state.toggleOpen}
                toggle={this.toggle}
                user={this.props.user}
              />
            </NavItem>
          </Nav>
          <NavbarSearch platform="mobile" />
          {/*<Nav className="nugl-topbar nugl-topbar-right right-actions d-block d-sm-block d-md-none">
            <NavItem id="notificationsPopover">
              <NavLink onClick={this.modalNotifications} href="#/" >
                {" "}
                <NotificationsLink user={this.props.user.id} platform="mobile" />
              </NavLink>
            </NavItem>
          </Nav>*/}
          <a className="nugl-topbar d-none d-sm-none d-md-block" href="/">
            <img
              src={NUGLCross}
              alt="nugl_cross"
              title="Head to Home & Refresh"
            />
          </a>
          {/*
          <a className="mobile-nav-messages d-block d-sm-block d-md-none" href="#/" data-toggle="modal" data-target="#dm_dialog">
            <i className="far fa-envelope" />
            <Badge pill={true} color="pink" className="badge-notification">1</Badge>
          </a>
          <a class="mobile-nav-messages d-block d-sm-block d-md-none" href="#/" data-toggle="modal" data-target="#dm_dialog">
            <i class="far fa-cog"></i>
          </a>
          */}
          <Collapse className="navbar-collapse" id="navbarsExampleDefault">
            <Nav className="mr-auto navbar-nav">
              <NavItem>
                <NavLink href={`/home`}>
                  <FontAwesomeIcon
                    icon={['far', 'home']}
                    className="mr-2"
                    size="lg"
                    id="homeButton"
                  />
                  <span className="mobile-hide">Home</span>
                </NavLink>
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipHomeOpen}
                  target="homeButton"
                  toggle={this.toggleHomeTooltip}
                >
                  Home
                </Tooltip>
              </NavItem>
              <NavItem>
                <NavLink href={`https://company.nugl.com/`}>
                  <FontAwesomeIcon
                    icon={['far', 'info-circle']}
                    className="mr-2"
                    size="lg"
                    id="aboutButton"
                  />
                  <span className="mobile-hide">About</span>
                </NavLink>
                <Tooltip
                  placement="top"
                  isOpen={this.state.tooltipAboutOpen}
                  target="aboutButton"
                  toggle={this.toggleAboutTooltip}
                >
                  About
                </Tooltip>
              </NavItem>
              {this.props.user.id && (
                <React.Fragment>
                  <NavItem id="notificationsPopover">
                    <NavLink onClick={this.modalNotifications} href="#/">
                      <NotificationsLink
                        toggle={this.modalNotifications}
                        user={this.props.user.id}
                        platform="desktop"
                        open={this.state.toggleNotifications}
                      />
                    </NavLink>
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipNotificationsOpen}
                      target="notificationsButton"
                      toggle={this.toggleNotificationsTooltip}
                    >
                      Notifications
                    </Tooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#/" onClick={this.leaveFeedback}>
                      <FontAwesomeIcon
                        icon={['far', 'flag']}
                        className="mr-2"
                        size="lg"
                        id="reportButton"
                      />
                      <span className="mobile-hide">Report an issue</span>
                    </NavLink>
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipReportOpen}
                      target="reportButton"
                      toggle={this.toggleReportTooltip}
                    >
                      Report an issue
                    </Tooltip>
                  </NavItem>
                </React.Fragment>
              )}
              {/* <Messages /> */}
            </Nav>

            <NavbarSearch platform="desktop" />
            <Nav className="right-actions d-none d-sm-none d-md-block">
              <NavItem>
                <AccountDropdown
                  isOpen={this.state.toggleOpen}
                  toggle={this.toggle}
                  user={this.props.user}
                />
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
        <FeedbackFormModal
          open={this.state.toggleFeedback}
          user={this.props.user}
          close={this.leaveFeedback}
        />
      </Navbar>
    )
  }

  toggle = () => {
    this.setState(p => ({
      toggleOpen: !p.toggleOpen,
    }))
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const WideWeb = withRouter(connect(mapStateToProps)(WideWebNavigation))

// const mapStateToProps = (state: any) => ({
//     user: state.sessionState.authUser
// });

// export const WideWeb = connect(mapStateToProps)(WideWebNavigation);
