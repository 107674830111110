import * as React from 'react'
import { Container, Nav, Row, Col, NavItem, NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faComments as fasComments,
  faUserFriends as fasUserFriends,
} from '@fortawesome/pro-solid-svg-icons'
import { FollowButton } from '../FollowButton'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class UnroutedProfileNavigation extends React.PureComponent {
  navigate = type => () => {
    this.props.history.push(`/${this.props.url}/${type}`)
  }
  render() {
    const route = this.props.match.params.subpath
    return (
      <div className="nav-scroller u-boxshadow sticky-top sticky-offset-nav">
        <Container className="d d-sm-block d-md-block">
          <Row noGutters>
            <Col md="4" xl="3" />
            <Col md="8" xl="9" className="nav-actions-bar">
              <Nav card className="nav-underline">
                <NavItem active={!route || route === 'reviews'}>
                  <NavLink
                    onClick={this.navigate('feed')}
                    active={!route || route === 'feed'}
                  >
                    <FontAwesomeIcon icon={fasComments} size="2x" />
                    <p className="mb-0 d-none d-sm-block">Chatter</p>
                  </NavLink>
                </NavItem>
                <NavItem active={route === 'reviews'}>
                  <NavLink
                    onClick={this.navigate('following')}
                    active={route === 'following'}
                  >
                    <FontAwesomeIcon icon={fasUserFriends} size="2x" />
                    <p className="mb-0 d-none d-sm-block">Following</p>
                  </NavLink>
                </NavItem>

                {this.props.user.id !== this.props.profile.id ? (
                  <NavItem className="nav-button">
                    <FollowButton profile={this.props.profile} />
                  </NavItem>
                ) : null}
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const ProfileNavigation = withRouter(
  connect(mapStateToProps)(UnroutedProfileNavigation),
)

export { ProfileNavigation }
