import * as React from 'react'
import { withRouter } from 'react-router'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Configure, Highlight } from 'react-instantsearch-dom'
import { SearchBox } from './SearchBar'
import { SearchPagination } from './Pagination'
import { SearchFilters } from './Filters'
import 'instantsearch.css/themes/reset.css'
import { Card, CardBody, Col, Container, Media, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { connectHits } from 'react-instantsearch-dom'
import { FollowButton } from '../FollowButton'

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
)

const Hits = ({ hits }) => {
  const returnHits = hits
    .filter(h => h.name || h.username)
    .map(hit => (
      <Hit key={`search-result-${hit.username}-${hit.id}`} hit={hit} />
    ))
  if (returnHits.length === 0) {
    return <p>No results found.</p>
  } else {
    return returnHits
  }
}

const CustomHits = connectHits(Hits)

class SearchPageU extends React.PureComponent {
  constructor(props) {
    super(props)
    let searchTerm
    if (props.location && props.location.state) {
      searchTerm = props.location.state.searchTerm || ''
    }
    this.state = {
      filters: '',
      following: false,
      location: '',
      searchTerm,
      type: '',
    }
  }

  onFilterUpdate = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      this.filter,
    )
  }

  onFollowToggle = () => {
    this.setState(
      p => ({
        following: !p.following,
      }),
      this.filter,
    )
  }

  filter = () => {
    // Convert state to facet filters
    let filters = ''
    if (this.state.type !== 'all') {
      filters += `(record_type:${this.state.type})`
    }
    this.setState({ filters: filters })
  }

  render() {
    return (
      <Container>
        <div className="dashboard">
          <Row noGutters>
            <Col md={4} xl={3} className="px-1">
              <SearchFilters
                filterCallback={this.onFilterUpdate}
                toggleCallback={this.onFollowToggle}
              />
            </Col>

            <Col md={8} xl={6} className="px-1">
              <InstantSearch
                searchClient={searchClient}
                indexName={process.env.REACT_APP_ALGOLIA_INDICES_USERS}
              >
                <Configure hitsPerPage={10} filters={this.state.filters} />

                <Card className="card bg-lightgray mb-2">
                  <CardBody className="card-body">
                    <Row noGutters>
                      <SearchBox
                        defaultRefinement={this.state.searchTerm || ''}
                        className="search-input"
                        placeholder={'Search NUGL'}
                      />
                    </Row>
                  </CardBody>
                </Card>

                <Card className="mb-2">
                  <CardBody>
                    <h5 className="card-title">
                      <a className="a-dark" href="_blank">
                        <span className="fa-layers fa-fw card-title-icons">
                          <FontAwesomeIcon
                            icon={faCircle}
                            transform="grow-9"
                            className="text-darkgray"
                          />
                          <FontAwesomeIcon
                            icon={faUser}
                            transform="shrink-3"
                            inverse
                          />
                        </span>{' '}
                        Results
                      </a>
                      {/* <small className="float-right"><a href="#/">See all</a></small> */}
                    </h5>
                    <CustomHits />
                  </CardBody>
                </Card>
                <div className="pagination">
                  <SearchPagination />
                </div>
              </InstantSearch>
            </Col>
            <Col
              md={3}
              className="px-1 d-none d-sm-none d-md-none d-lg-none d-xl-block"
            />
          </Row>
        </div>
      </Container>
    )
  }
}

function HitU(props) {
  let nameAttribute = ''
  if (props.hit.name) {
    nameAttribute = 'name'
  } else {
    nameAttribute = 'username'
  }
  return (
    <div className="usercard-container">
      <div id="profileInfo" className="usercard">
        <Media className="align-items-center">
          <div className="usercard-picture">
            <img src={props.hit.avatar} alt="" className="img-round" />
          </div>
          <Media body className="media-body">
            <div className="usercard-details">
              <p className="truncate">
                <a
                  href="#/"
                  className="usercard-title"
                  onClick={() => props.history.push('/' + props.hit.username)}
                >
                  <Highlight attribute={nameAttribute} hit={props.hit} />
                </a>
              </p>
              <p className="truncate">
                <span className="usercard-info">{props.hit.headline}</span>
              </p>
            </div>
          </Media>
          <div className="follow-options">
            <FollowButton profile={{ id: props.hit.objectID }} />
          </div>
        </Media>
        <div className="usercard-about">
          <p>
            <Highlight attribute="details" hit={props.hit} />
          </p>
        </div>
      </div>
    </div>
  )
}

const Hit = withRouter(HitU)

const SearchPage = withRouter(SearchPageU)

export { SearchPage }
