import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { faPencil } from '@fortawesome/pro-regular-svg-icons'

class IconButton extends React.PureComponent {
  render() {
    return (
      <span className="fa-stack fa-lg">
        <FontAwesomeIcon
          icon={faCircle}
          className="fa-stack-2x text-blue"
          size="1x"
        />
        <FontAwesomeIcon
          icon={faPencil}
          className="fa-stack-1x text-white"
          size="1x"
        />
      </span>
    )
  }
}

export { IconButton }
