import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { auth } from '../../firebase'

class SignOutComponent extends React.Component {
  signOut = () => {
    auth.doSignOut()
    this.props.onSetUsers({ id: null })
    this.props.onSetAuthUser(null)
    this.props.history.push('/')
  }

  componentDidMount() {
    this.signOut()
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => ({
  onSetUsers: user => dispatch({ type: 'SET_USER', user }),
  onSetAuthUser: authUser => dispatch({ type: 'AUTH_USER_SET', authUser }),
})

export const SignOut = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(SignOutComponent),
)
