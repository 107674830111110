import * as React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'

import { connectPagination } from 'react-instantsearch-dom'

const pagination = ({ currentRefinement, nbPages, refine }) => (
  <Pagination>
    <PaginationItem>
      <PaginationLink
        href="#/"
        style={{ color: '#657786' }}
        onClick={event => {
          event.preventDefault()
          refine(
            currentRefinement > 1 ? currentRefinement - 1 : currentRefinement,
          )
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </PaginationLink>
    </PaginationItem>
    {new Array(nbPages).fill(null).map((_, index) => {
      const page = index + 1
      const style = {
        color: currentRefinement === page ? '#00A1E4' : '#657786',
      }

      return (
        <PaginationItem key={index}>
          <PaginationLink
            href="#/"
            style={style}
            onClick={event => {
              event.preventDefault()
              refine(page)
            }}
          >
            {page}
          </PaginationLink>
        </PaginationItem>
      )
    })}
    <PaginationItem>
      <PaginationLink
        href="#/"
        style={{ color: '#657786' }}
        onClick={event => {
          event.preventDefault()
          refine(
            currentRefinement < nbPages
              ? currentRefinement + 1
              : currentRefinement,
          )
        }}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </PaginationLink>
    </PaginationItem>
  </Pagination>
)

export const SearchPagination = connectPagination(pagination)
