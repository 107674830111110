import * as React from 'react'
import { firebase } from '../../firebase'
import { Input, FormGroup } from 'reactstrap'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { uuid } from '../../functions'

class CreateCommentU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      loading: false,
    }
  }

  handleTextUpdate = event => {
    // Set a sane upper limit so users don't blow up the database
    if (event.target.value.length <= 512) {
      this.setState({
        text: event.target.value,
      })
    }
  }

  handleKeyPress = event => {
    if (event.charCode === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({ loading: true })

    const timestamp = Date.now()
    const commentId = uuid.generate()

    this.setState({
      text: '',
    })

    await firebase.firestore
      .doc(`${this.props.post.path}/comments/${commentId}`)
      .set(
        {
          author: firebase.firestore.doc(`people/${this.props.user.id}`),
          notifier: firebase.firestore.doc(`people/${this.props.user.id}`),
          text: this.state.text,
          timestamp,
        },
        { merge: true },
      )
  }
  render() {
    return (
      <div className="post-comment">
        <div className="media">
          <img
            className="rounded-circle img-fluid mr-2"
            alt=""
            src={this.props.user.avatar}
          />
          <div className="media-body">
            <FormGroup className="form-group input-group-sm">
              <Input
                onChange={this.handleTextUpdate}
                onKeyPress={this.handleKeyPress}
                value={this.state.text}
                type="textarea"
                rows={1}
                name="commentBody"
                className="comment-input"
                placeholder="Write a comment..."
              />
            </FormGroup>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})

export const CreateComment = withRouter(
  connect(mapStateToProps)(CreateCommentU),
)
