import * as React from 'react'

class ProfileCover extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
    }
  }

  render() {
    return (
      <div
        className="profile-cover"
        style={{ backgroundImage: `url(${this.props.cover})` }}
      >
        {/* <div className="profile-cover-edit">
                    <IconButton icon={faCamera} />
                    <input id="file-input" type="file" />
                </div> */}
      </div>
    )
  }
}

ProfileCover.defaultProps = {
  cover: 'http://nugl.goboxy.com/assets/img/default/nugl_cover_image.png',
}

export { ProfileCover }
