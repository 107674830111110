import * as React from 'react'
import axios from 'axios'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'

class BlockAd extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      image: '',
      title: '',
      url: '',
    }
  }
  async componentDidMount() {
    try {
      const ad = await axios.get(
        `${process.env.REACT_APP_NUGL_V1_FUNCTIONS_HOST}/a/gen`,
        {
          params: {
            type: 'BLOCK',
          },
        },
      )
      const image = ad.data.imageUrl
      const title = ad.data.metadata ? ad.data.metadata.title : 'NUGL Network'
      const url = ad.data.clickUrl
      this.setState({
        image,
        title,
        url,
      })
    } catch (e) {
      console.error('Error loading gen.')
      this.setState({
        image: 'http://nugl.goboxy.com/assets/img/samples/block_sample_01.jpg',
        url: 'https://www.o2grow.com/',
      })
    }
  }

  state = {
    dropdownOpen: false,
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  render() {
    return (
      <div className="sponsored-block">
        <a href={this.state.url} target="_blank" rel="noopener noreferrer">
          <img
            src={this.state.image}
            title={`Clicking takes you to ${this.state.url}.`}
            alt={this.state.title}
            className="img-fluid"
          />
        </a>
        <div className="sponsored-callout">
          Ad
          {false && (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle
                tag="span"
                onClick={this.toggle}
                data-toggle="dropdown"
                aria-expanded={this.state.dropdownOpen}
              >
                <a
                  href="#/"
                  className="dropdown-toggle ml-1"
                  id="dropdownAd1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faEllipsisH} className="text-dark" />
                </a>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <a className="dropdown-item" href="#/">
                    Manage ad preferences
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a
                    className="dropdown-item"
                    href="https://company.nugl.com/advertise.html"
                  >
                    Advertise on NUGL
                  </a>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
    )
  }
}

export { BlockAd }
