import * as React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import * as routes from '../../constants/routes'
import { firebase } from '../../firebase'
import { Account } from '../Account'
import { Home } from '../Home'
import { WideWebNavigation } from '../Navigation'
import { PasswordForget } from '../PasswordForget'
import { ProfilePage } from '../ProfilePage'
import { ReportPage } from '../ReportPage'
import { SearchPage } from '../SearchPage'
import { withAuthentication } from '../Session/withAuthentication'
import { SignUp } from '../SignUp'
import { SignOut } from '../SignOut'
import { WebMd } from '../WebMd'

class AppComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authUser: null,
    }
  }

  componentDidMount() {
    firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ authUser }))
        : this.setState(() => ({ authUser: null }))
    })
  }

  render() {
    // TODO: This is a hacky fix and the non-auth page will flash for a moment. Works but needs a better fix.
    const isAuth = Boolean(this.state.authUser)
    return (
      <BrowserRouter>
        <div className="d-flex" id="wrapper">
          <div className="page-overlay" />
          <div id="page-content-wrapper">
            <WideWebNavigation />
            <Switch>
              <Route
                exact={true}
                path={routes.WEBMD_CONFIRMATION}
                component={WebMd}
              />
              <Route exact={true} path={routes.WEBMD} component={WebMd} />
              <Route
                exact={true}
                path={'/secret/report/area'}
                component={ReportPage}
              />
              <Route
                exact={true}
                path={routes.LANDING}
                component={isAuth ? Home : SignUp}
              />
              <Route
                exact={true}
                path={routes.SIGN_UP}
                component={isAuth ? Home : SignUp}
              />
              <Route
                exact={true}
                path={routes.SIGN_IN}
                component={isAuth ? Home : SignUp}
              />
              <Route exact={true} path={routes.SIGN_OUT} component={SignOut} />
              <Route
                exact={true}
                path={routes.PASSWORD_FORGET}
                component={isAuth ? Home : PasswordForget}
              />
              <Route
                exact={true}
                path={`${
                  routes.HOME
                }/:subpath(chatter|following|notifications)`}
                component={Home}
              />
              <Route exact={true} path={routes.HOME} component={Home} />
              <Route exact={true} path={routes.ACCOUNT} component={Account} />
              <Route exact={true} path={'/search'} component={SearchPage} />
              <Route
                exact={true}
                path="/:username/:subpath(feed|reviews|menu|images|post)/:postId"
                render={({ match }) => (
                  <ProfilePage key={match.params.username} />
                )}
              />
              <Route
                path="/:username/:subpath(following)"
                render={({ match }) => (
                  <ProfilePage key={match.params.username} />
                )}
              />
              <Route
                path="/:username"
                render={({ match }) => (
                  <ProfilePage key={match.params.username} />
                )}
              />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    )
  }
}

export const App = withAuthentication(AppComponent)
