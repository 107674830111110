import * as React from 'react'
import { auth } from '../../firebase'

export class PasswordForgetForm extends React.Component {
  INITIAL_STATE = {
    email: '',
    error: null,
  }

  propKey(propertyName, value) {
    return { [propertyName]: value }
  }

  constructor(props) {
    super(props)

    this.state = { ...PasswordForgetForm.INITIAL_STATE }
  }

  onSubmit = event => {
    const { email } = this.state

    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...PasswordForgetForm.INITIAL_STATE }))
      })
      .catch(error => {
        this.setState(PasswordForgetForm.propKey('error', error))
      })

    event.preventDefault()
  }

  render() {
    const { email, error } = this.state
    const isInvalid = email === ''

    return (
      <form onSubmit={event => this.onSubmit(event)}>
        <input
          value={email}
          onChange={event => this.setStateWithEvent(event, 'email')}
          type="text"
          placeholder="Email Address"
        />
        <button disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }

  setStateWithEvent(event, columnType) {
    this.setState(PasswordForgetForm.propKey(columnType, event.target.value))
  }
}
