import * as React from 'react'
import { Modal } from 'reactstrap'
import { FeedPost } from '../FeedPost'

class SinglePostModal extends React.PureComponent {
  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.close}>
        {this.props.post && (
          <FeedPost
            profile={this.props.profile}
            post={this.props.post}
            key={this.props.post.id + '-post-singlePost'}
          />
        )}
      </Modal>
    )
  }
}

export { SinglePostModal }
