import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Select from 'react-select'
import DatePicker from 'react-datepicker'

import { Form, FormGroup, Button, Label, Input, FormFeedback } from 'reactstrap'

import * as routes from '../../constants/routes'
import { firebase } from '../../firebase'

import 'react-datepicker/dist/react-datepicker.css'

const options = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
]

class UnroutedAppointmentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: new Date(),
      time: null,
      name: '',
      email: '',
      phone: '',
      error: null,
      isValid: false,
      validationMessages: {},
      validationStates: {
        date: true,
        time: true,
        name: null,
        phone: null,
        email: null,
        state: null,
      },
    }
  }

  change = type => async e => {
    let targetValue = null
    if (type === 'state') {
      targetValue = e
    } else if (type === 'date' || type === 'time') {
      targetValue = e
    } else {
      targetValue = e.target.value
    }
    const value = targetValue
    await this.setState({ [type]: value })
    this.validate(type)(value)
  }
  setValidations = (type, validity, message) => {
    this.setState(p => {
      const newValidation = {
        ...p.validationStates,
        [type]: validity,
      }
      const isValid = Object.keys(newValidation).every(e => newValidation[e])
      return {
        isValid,
        validationStates: {
          ...p.validationStates,
          [type]: validity,
        },
        validationMessages: {
          ...p.validationMessages,
          [type]: message,
        },
      }
    })
  }

  validate = type => async value => {
    if (type === 'email') {
      if (!value || value.indexOf('@') === -1 || value.indexOf('.') === -1) {
        this.setValidations(type, false, 'Not a valid email address.')
        return
      }
    }
    this.setValidations(type, true, 'Looks good.')
  }

  onSubmit = async event => {
    event.preventDefault()
    const { history } = this.props
    const { date, time, name, phone, email, state } = this.state
    try {
      await firebase.firestore
        .collection('appointments')
        .add({ date, time, name, phone, email, state: state.value })
      history.push(`${routes.WEBMD}/confirmation`)
    } catch (e) {
      console.error(e)
      this.setState({ error: e.message })
    }
  }

  render() {
    return (
      <React.Fragment>
        <h4 className="text-center mb-3" id="schedule">
          Appointment Request Form
        </h4>
        <p>
          Please fill out your details in the form below to request an
          appointment with a NUGL MD doctor.
        </p>
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label>Date</Label>
            <div style={{ display: 'block' }}>
              <DatePicker
                className="form-control"
                selected={this.state.date}
                onChange={this.change('date')}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <Label>Time</Label>
            <div style={{ display: 'block' }}>
              <DatePicker
                className="form-control"
                selected={this.state.time}
                onChange={this.change('time')}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input
              value={this.state.name}
              invalid={
                !this.state.validationStates.name &&
                this.state.validationStates.name !== null
              }
              valid={
                this.state.validationStates.name &&
                this.state.validationStates.name === null
              }
              type="text"
              onChange={this.change('name')}
            />
            <FormFeedback
              invalid={
                !this.state.validationStates.name &&
                this.state.validationStates.name !== null
              }
              valid={
                this.state.validationStates.name &&
                this.state.validationStates.name === null
              }
            >
              {this.state.validationMessages.name}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Phone Number</Label>
            <Input
              value={this.state.phone}
              invalid={
                !this.state.validationStates.phone &&
                this.state.validationStates.phone !== null
              }
              valid={
                this.state.validationStates.phone &&
                this.state.validationStates.phone === null
              }
              type="text"
              onChange={this.change('phone')}
            />
            <FormFeedback
              invalid={
                !this.state.validationStates.phone &&
                this.state.validationStates.phone !== null
              }
              valid={
                this.state.validationStates.phone &&
                this.state.validationStates.phone === null
              }
            >
              {this.state.validationMessages.email}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              value={this.state.email}
              invalid={
                !this.state.validationStates.email &&
                this.state.validationStates.email !== null
              }
              valid={
                this.state.validationStates.email &&
                this.state.validationStates.email === null
              }
              type="email"
              onChange={this.change('email')}
            />
            <FormFeedback
              invalid={
                !this.state.validationStates.email &&
                this.state.validationStates.email !== null
              }
              valid={
                this.state.validationStates.email &&
                this.state.validationStates.email === null
              }
            >
              {this.state.validationMessages.email}
            </FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label>State</Label>
            <Select
              value={this.state.state}
              onChange={this.change('state')}
              options={options}
            />
            <FormFeedback
              invalid={
                !this.state.validationStates.state &&
                this.state.validationStates.state !== null
              }
              valid={
                this.state.validationStates.state &&
                this.state.validationStates.state === null
              }
            >
              {this.state.validationMessages.state}
            </FormFeedback>
          </FormGroup>
          <br />
          <div className="text-center">
            <Button disabled={!this.state.isValid} className="btn-pink btn-lg">
              Request Appointment
            </Button>
          </div>
          {this.state.error}
        </Form>
      </React.Fragment>
    )
  }

  setStateWithEvent(event, columnType) {
    this.setState(p => ({ [columnType]: event.target.value }))
  }
}

const mapDispatchToProps = dispatch => ({
  setAppUser: user => {
    return dispatch({ type: 'SET_USER', user })
  },
})

export const AppointmentForm = withRouter(
  connect(
    () => ({}),
    mapDispatchToProps,
  )(UnroutedAppointmentForm),
)
