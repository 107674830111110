import * as React from 'react'
import { firebase } from '../../firebase'
import { PostOptionDropdown } from '../PostOptionDropdown'
import { CreateComment, CommentContainer } from '../PostComment'
import { faCaretRight } from '@fortawesome/pro-regular-svg-icons'
import { LikeButton } from './LikeButton'
import { CommentButton } from './CommentButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { EditText } from './EditText'
import { PostText } from './PostText'
import { Collapse } from 'reactstrap'
import { format } from 'timeago.js'

class FeedPostU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      author: {
        id: '',
      },
      editing: false,
      expandedComments: false,
      loaded: false,
      post: {
        avatar: '',
      },
    }
    this.expandComments = this.expandComments.bind(this)
  }

  async componentDidMount() {
    const feedId = this.props.post.parent.parent.id
    this.unsubscribe = await firebase.firestore
      .doc(this.props.post.path)
      .onSnapshot(async snapshot => {
        if (!snapshot.exists) {
          return
        }
        const doc = snapshot.data()
        const author = (await await doc.author.get()).data()
        // TODO: save receiver ref to post.
        const receiverQuery = await firebase.firestore
          .collection('people')
          .where('username', '==', feedId)
          .get()
        const receiver = receiverQuery.docs[0]
        this.setState({
          post: doc,
          loaded: true,
          author,
          profilePage: { ...receiver.data(), id: receiver.id },
        })
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  expandComments() {
    this.setState(p => ({
      expandedComments: !p.expandedComments,
    }))
  }

  formatTimestamp = timestamp => {
    var date = new Date(timestamp)
    return `${date.toLocaleString('en-us', {
      month: 'short',
    })} ${date.getDate()}`
  }

  editPost = () => {
    this.setState({ editing: true })
  }

  updateText = async text => {
    if (this.state.author.id === this.props.user.id) {
      await firebase.firestore
        .doc(this.props.post.path)
        .set({ text }, { merge: true })
      await firebase.firestore
        .doc(`users/${this.props.user.id}/posts/${this.props.post}`)
        .set({ text }, { merge: true })
      this.setState({ editing: false })
    }
  }

  render() {
    if (!this.state.loaded || !this.state.profilePage) {
      return null
    }
    return (
      <div className="card post mb-2">
        <div className="profile-info-edit">
          <PostOptionDropdown
            post={this.state.post}
            profilePage={this.state.profilePage}
            user={this.props.user}
            editPost={this.editPost}
          />
        </div>
        <div className="card-body">
          <div className="media">
            <a href="#/">
              <img
                src={this.state.author.avatar}
                alt="poster's avatar"
                className="feed-user rounded-circle img-fluid mr-2"
              />
            </a>
            <div className="media-body">
              {/* {this.props.profile && this.state.author.id !== this.props.profile.id && `-> ${this.props.profile.name}`}  */}
              <p className="mb-0">
                <b>
                  <Link to={'/' + this.state.author.username}>
                    {this.state.author.name || this.state.author.username}
                  </Link>
                </b>
                {this.state.profilePage.id !== this.state.author.id && (
                  <React.Fragment>
                    {' '}
                    <FontAwesomeIcon icon={faCaretRight} />{' '}
                    <b>
                      <Link to={'/' + this.state.profilePage.username}>
                        {this.state.profilePage.name ||
                          this.state.profilePage.username}
                      </Link>
                    </b>
                  </React.Fragment>
                )}{' '}
                <span
                  className="time"
                  title={format(this.state.post.timestamp)}
                >
                  {' '}
                  {this.formatTimestamp(this.state.post.timestamp)}
                </span>
              </p>
              {!this.state.editing ? (
                <PostText text={this.state.post.text} />
              ) : (
                <EditText
                  text={this.state.post.text}
                  update={this.updateText}
                />
              )}
              {this.state.post.withImage && (
                <img
                  src={this.state.post.image}
                  alt="nugl_launcher"
                  className="img-fluid"
                />
              )}
              <hr />
              <div className="post-actions">
                <LikeButton
                  profile={this.props.profile}
                  post={this.props.post}
                />
                <CommentButton
                  clickMethod={this.expandComments}
                  profile={this.props.profile}
                  post={this.props.post}
                />
              </div>
            </div>
          </div>
          {this.props.profile && (
            <Collapse isOpen={this.state.expandedComments}>
              <hr />
              <CommentContainer
                profile={this.props.profile}
                user={this.props.user}
                post={this.props.post}
              />
              <CreateComment post={this.props.post} />
            </Collapse>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export const FeedPost = withRouter(connect(mapStateToProps)(FeedPostU))
