import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-regular-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'

import { Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap'

export class SearchFilters extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      following: false,
    }
  }

  toggleSwitch = () => {
    this.setState(
      p => ({
        following: !p.following,
      }),
      () => {
        this.props.toggleCallback()
      },
    )
  }

  handleChange = event => {
    const name = event.target.name
    const value = event.target.value

    this.props.filterCallback(name, value)
  }

  render() {
    return (
      <div className="sticky-sidebar">
        <div className="sticky-sidebar-inner">
          <Card id="filter-card" className="card bg-lightgray mb-2">
            <CardBody>
              <h5 className="card-title">
                <a className="a-dark" href="#/">
                  <span className="fa-layers fa-fw card-title-icons">
                    <FontAwesomeIcon
                      icon={faCircle}
                      transform="grow-9"
                      className="text-darkgray"
                    />
                    <FontAwesomeIcon
                      icon={faFilter}
                      transform="shrink-3"
                      inverse
                    />
                  </span>{' '}
                  Filter
                </a>
              </h5>
              <Form>
                <FormGroup>
                  <Label for="type">Type</Label>
                  <Input type="select" name="type" onChange={this.handleChange}>
                    <option value="all">All results</option>
                    <option value="people">People</option>
                    <option value="businesses">Businesses</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="location">Location</Label>
                  <Input
                    type="select"
                    name="location"
                    onChange={this.handleChange}
                    disabled
                  >
                    <option value="d">Coming soon</option>
                    <option value="all">All locations</option>
                    <option value="near">Near you</option>
                  </Input>
                </FormGroup>
                {/* <FormGroup className="mt-3">
                  <Label>Following</Label>
                  <ToggleSwitch onToggle={this.toggleSwitch} className="float-right" />
                </FormGroup> */}
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
