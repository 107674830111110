import * as React from 'react'
import { withRouter } from 'react-router'
import { firebase } from '../../firebase'
import { CreatePost } from '../CreatePost'
import { FeedPosts } from '../FeedPosts'

class FeedU extends React.PureComponent {
  state = {
    posts: [],
    loading: false,
  }

  // TODO: limit to ~10 and paginate
  // TODO: Include loading
  //     <div className="d-flex justify-content-center mb-2">
  //     <button className="btn btn-outline" type="button" disabled>
  //       <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
  //       Loading...
  //           </button>
  //   </div>
  async componentDidMount() {
    this.setState({ loading: true })
    this.unsubscribe = await firebase.firestore
      .collection(`feeds/${this.props.match.params.username}/posts`)
      .orderBy('timestamp', 'desc')
      .onSnapshot(async snapshot => {
        const docs = snapshot.docs.map(shot => shot.ref)
        this.setState({ posts: docs, loading: false })
      })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  render() {
    return (
      <React.Fragment>
        <CreatePost feedId={this.props.match.params.username} />
        <FeedPosts
          profile={this.props.profile}
          posts={this.state.posts}
          loading={this.state.loading}
        />
      </React.Fragment>
    )
  }
}

export const Feed = withRouter(FeedU)
