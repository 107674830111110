import * as React from 'react'
import { faChevronDown, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Collapse, Tooltip } from 'reactstrap'

class ProfileDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      tooltipOpen: false,
    }

    this.toggleDetails = this.toggleDetails.bind(this)
    this.toggleTooltip = this.toggleTooltip.bind(this)
  }

  toggleDetails = () => {
    this.setState(previousState => ({
      expanded: !previousState.expanded,
    }))
  }

  toggleTooltip = () => {
    this.setState(previousState => ({
      tooltipOpen: !previousState.tooltipOpen,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <div onClick={this.toggleDetails} className="details-expander">
          <span
            className="fa-layers fa-fw details-icon"
            id="details-expand"
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon
              icon={faCircle}
              transform="grow-20"
              color="#ccd6dd"
            />
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={this.state.expanded ? 180 : null}
              inverse
            />
          </span>
          <Tooltip
            placement="top"
            isOpen={this.state.tooltipOpen}
            target="details-expand"
            toggle={this.toggleTooltip}
          >
            {this.state.expanded ? 'Hide' : 'More details'}
          </Tooltip>
        </div>
        <Collapse isOpen={this.state.expanded}>
          <p>{this.props.details}</p>
        </Collapse>
      </React.Fragment>
    )
  }
}

export { ProfileDetails }
