import * as React from 'react'
import { BlockAd } from '../Advertisement'
import { Footer } from './Footer'
import { SuggestedProfiles } from './SuggestedProfiles'

class RightColumn extends React.Component {
  render() {
    return (
      <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div id="suggested-card" className="card mb-2">
          <SuggestedProfiles />
        </div>
        <div className="sticky-top sticky-offset-subnav">
          <BlockAd />
          <Footer />
        </div>
      </div>
    )
  }
}

export { RightColumn }
