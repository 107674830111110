import * as React from 'react'
import { firebase } from '../../firebase'
import { PostComment } from './PostComment'
import { withRouter } from 'react-router'

class CommentContainerU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      comments: [],
      extra: 0,
      loaded: false,
      shownComments: [],
    }
  }

  async componentDidMount() {
    const commentsPath = `${this.props.post.path}/comments`
    this.unsubscribe = await firebase.firestore
      .collection(commentsPath)
      .orderBy('timestamp', 'asc')
      .onSnapshot(async snapshot => {
        const snapshotComments = []
        for (const shot of snapshot.docs) {
          const snapshotData = shot.data()
          const author = (await snapshotData.author.get()).data()
          if (!author) continue
          const liked =
            snapshotData.liked &&
            snapshotData.liked.includes(this.props.user.id)
          const text = snapshotData.text
          const timestamp = snapshotData.timestamp
          const modified = snapshotData.modified
          snapshotComments.push(
            <PostComment
              key={`comment-${shot.id}`}
              allLikes={snapshotData.liked}
              liked={liked}
              userId={this.props.user.id}
              path={commentsPath + '/' + shot.id}
              name={author.name || author.username}
              username={author.username}
              isAuthor={author.id === this.props.user.id}
              avatar={author.avatar}
              text={text}
              timestamp={timestamp}
              modified={modified}
            />,
          )
        }
        this.setState(p => ({
          comments: snapshotComments,
          extra: snapshotComments.length - 2,
          shownComments: snapshotComments.slice(0, 2),
        }))
      })
    this.setState({ loaded: true })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  showMore = () => {
    this.setState(p => ({
      extra: p.extra - 3,
      shownComments: this.state.comments.slice(
        0,
        this.state.shownComments.length + 3,
      ),
    }))
  }

  render() {
    return (
      <React.Fragment>
        <div className="commentContainer">{this.state.shownComments}</div>
        {this.state.extra > 0 && (
          <div className="mb-2">
            <a href="#/" className="show-more-link" onClick={this.showMore}>
              View more comments
            </a>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export { CreateComment } from './CreateComment'
export { PostComment } from './PostComment'

export const CommentContainer = withRouter(CommentContainerU)
