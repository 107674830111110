import * as React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { firebase } from '../../firebase'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class FollowButtonU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      following: false,
      reference: null,
      loggedIn: false,
    }
  }
  async componentDidMount() {
    if (!this.props.user || !this.props.user.id || this.props.user.id === '') {
      return
    }
    const reference = await firebase.firestore.doc(
      `people/${this.props.user.id}/following/${this.props.profile.id}`,
    )
    const currentProfileFeedReference = firebase.firestore.doc(
      `feeds/${this.props.profile.data().username}`,
    )
    const data = await reference.get()
    this.setState({
      reference,
      currentProfileFeedReference,
      following: data.exists,
      loggedIn: true,
    })
  }
  follow = () => {
    this.state.reference.set({
      timestamp: Date.now(),
      feedRef: this.state.currentProfileFeedReference,
    })
    this.setState({
      following: true,
    })
  }
  unfollow = () => {
    this.state.reference.delete()
    this.setState({
      following: false,
    })
  }
  login = () => {
    this.props.history.push('/login')
  }
  render() {
    if (!this.state.loggedIn) {
      return (
        <Button outline onClick={this.login} className="btn-outline mr-1">
          Log in to follow
        </Button>
      )
    }
    return (
      <Button
        outline
        onClick={this.state.following ? this.unfollow : this.follow}
        className={this.state.following ? 'btn-blue mr-1' : 'btn-outline mr-1'}
      >
        <FontAwesomeIcon
          icon={this.state.following ? ' ' : faUserPlus}
          className="mr-1"
        />
        {this.state.following ? 'Following' : 'Follow'}
      </Button>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const FollowButton = withRouter(connect(mapStateToProps)(FollowButtonU))

export { FollowButton }
