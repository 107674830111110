import * as React from 'react'
import { firebase } from '../../firebase'
import { Input } from 'reactstrap'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

class EditCommentU extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: this.props.text,
    }
  }

  handleTextUpdate = event => {
    // Set a sane upper limit so users don't blow up the database
    if (event.target.value.length <= 512) {
      this.setState({
        text: event.target.value,
      })
    }
  }

  handleKeyPress = event => {
    if (event.charCode === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit = async e => {
    e.preventDefault()

    await firebase.firestore.doc(this.props.path).set(
      {
        text: this.state.text,
        modified: Date.now(),
      },
      { merge: true },
    )

    this.props.toggle()
  }

  render() {
    return (
      <Input
        name="editComment"
        onChange={this.handleTextUpdate}
        onKeyPress={this.handleKeyPress}
        rows={1}
        type="textarea"
        value={this.state.text}
      />
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
})

export const EditComment = withRouter(connect(mapStateToProps)(EditCommentU))
