import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import naturalsort from 'natural-sort'
import { firebase } from '../../firebase'
import { ProfileCover } from '../ProfileCover'
import { ProfileImage } from '../ProfileImage'
import { ProfileNavigation } from '../ProfileNavigation'
import { BusinessProfile } from '../ProfileInfo'
import { RightColumn } from '../ProfileContent/RightColumn'
import { Feed } from '../Feed'
import { SinglePostModal } from '../SinglePostModal'
import { BlockAd } from '../Advertisement'
import { Following, FollowingBlock } from '../Following'

class UnroutedProfilePage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      profile: null,
      singlePostModalOpen: false,
      singlePost: {},
      singlePostId: '',
      following: [],
    }
  }

  async getPeople(usernames) {
    const srt = naturalsort()
    const docs = (await Promise.all(
      usernames.map(async username => {
        const snap = await firebase.firestore
          .collection(`people`)
          .where('username', '==', username)
          .get()
        const docs = snap.docs
        if (docs.length === 0) {
          return null
        }
        return docs[0]
      }),
    ))
      .filter(doc => doc !== null)
      .map(doc => doc.data())
      .sort((aDoc, bDoc) =>
        srt(aDoc.name || aDoc.username, bDoc.name || bDoc.username),
      )
    return docs
  }

  async componentDidMount() {
    const { match, history } = this.props
    const { username } = match.params

    const db = await (await firebase.firestore.doc(`urls/${username}`)).get()
    if (!db.exists) {
      // TODO: URL
      history.push('/')
      return
    }
    const userRef = await firebase.firestore.doc(db.data().user).get()
    const isBusiness = userRef.business
    if (isBusiness) {
      this.unsubscribe = userRef.businessRef.onSnapshot(snapshot => {
        this.setState({
          avatar: snapshot.data().avatar,
          cover: snapshot.data().cover,
          isBusiness,
          user: userRef,
        })
      })
    } else {
      this.unsubscribe = firebase.firestore
        .doc(`people/${userRef.id}`)
        .onSnapshot(snapshot => {
          this.setState({
            avatar: snapshot.data().avatar,
            cover: snapshot.data().cover,
            isBusiness,
            user: userRef,
          })
        })
    }
    if (match.params.subpath === 'post') {
      this.setState({
        singlePostID: match.params.postId,
        singlePostModalOpen: true,
        singlePost: (await firebase.firestore
          .doc(`feeds/${username}/posts/${match.params.postId}`)
          .get()).data(),
      })
    }

    const profileRef = userRef.business
      ? userRef.businessRef
      : firebase.firestore.doc(`people/${userRef.id}`)

    const following = await profileRef.collection('following').get()

    const followingUsernames =
      following.size > 0
        ? await Promise.all(
            await following.docs
              .filter(e => e.data().feedRef)
              .map(async e => {
                return await e.data().feedRef.id
              }),
          )
        : []

    const followingPeople = await this.getPeople(followingUsernames)

    this.setState({
      profile: (await profileRef.get()).data(),
      following: followingPeople,
    })
  }

  async componentDidUpdate() {
    const { match } = this.props
    if (
      match.params.subpath === 'post' &&
      this.state.singlePostID !== match.params.subpath &&
      !this.state.singlePostModalOpen
    ) {
      const { username } = match.params
      this.setState({
        singlePostID: match.params.postId,
        singlePostModalOpen: true,
        singlePost: (await firebase.firestore
          .doc(`feeds/${username}/posts/${match.params.postId}`)
          .get()).data(),
        singlePostRef: firebase.firestore.doc(
          `feeds/${username}/posts/${match.params.postId}`,
        ),
      })
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  closeModal = () => {
    const { match, history } = this.props
    this.setState({
      singlePostModalOpen: false,
      singlePostID: '',
    })
    history.push(`/${match.params.username}`)
  }

  render() {
    const { match } = this.props
    const { profile, following, user } = this.state
    const section = match.params.subpath

    if (!user) return <FontAwesomeIcon icon={faSpinner} spin />

    return (
      <React.Fragment>
        <ProfileCover cover={this.state.cover} />
        <ProfileImage avatar={this.state.avatar} />
        <ProfileNavigation profile={user} url={match.params.username} />
        <Container>
          <div className="public-profile">
            <Row noGutters>
              <Col md={4} xl={3} className="px-1">
                <div className="sticky-sidebar">
                  <div className="sticky-sidebar-inner">
                    <BusinessProfile
                      profile={user}
                      isBusiness={this.state.isBusiness}
                      user={user}
                    />
                    <div className="mb-2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                      <FollowingBlock profile={profile} people={following} />
                    </div>

                    <div className="mb-2 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                      <BlockAd />
                    </div>
                    <div className="d-none d-sm-none d-md-block d-lg-block d-xl-none">
                      <RightColumn />
                    </div>
                  </div>
                </div>
              </Col>
              {/* MIDDLE COLUMN */}
              <Col id="feedPosts" md={8} xl={6} className="px-1">
                {(!section || section === 'feed' || section === 'post') && (
                  <Feed profile={user} />
                )}
                {section === 'following' && <Following following={following} />}
              </Col>
              {/* RIGHT COLUMN DESKTOP */}

              <Col
                md={3}
                className="px-1 d-block d-sm-block d-md-none d-lg-none d-xl-block"
              >
                <RightColumn />
              </Col>
            </Row>
          </div>
          {this.state.singlePostModalOpen && (
            <SinglePostModal
              profile={user}
              open={this.state.singlePostModalOpen}
              post={this.state.singlePostRef}
              close={this.closeModal}
            />
          )}
        </Container>
      </React.Fragment>
    )
  }
}

const ProfilePage = withRouter(
  connect(state => ({
    user: state.user,
  }))(UnroutedProfilePage),
)

export { ProfilePage }
