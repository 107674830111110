import * as React from 'react'
import { withRouter } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/pro-regular-svg-icons'
import { DropdownItem } from 'reactstrap'
import { connect } from 'react-redux'
import { firebase } from '../../firebase'

class AuthorActions extends React.PureComponent {
  delete = async () => {
    try {
      const { username } = this.props.profilePage
      const feedPost = await firebase.firestore.doc(
        `feeds/${username}/posts/${this.props.post.id}`,
      )
      await feedPost.delete()
      const userPost = await firebase.firestore.doc(
        `users/${this.props.post.author.id}/posts/${this.props.post.id}`,
      )
      await userPost.delete()
    } catch (e) {
      console.error(e)
    }
  }
  render() {
    if (this.props.post.author.id !== this.props.user.id) {
      return null
    }
    return (
      <React.Fragment>
        {/* <DropdownItem divider /> */}
        <DropdownItem onClick={this.props.editPost}>
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit post
        </DropdownItem>
        <DropdownItem onClick={this.delete}>
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Delete post
        </DropdownItem>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const RoutedAuthorActions = withRouter(connect(mapStateToProps)(AuthorActions))

export { RoutedAuthorActions as AuthorActions }
