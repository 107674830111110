import * as React from 'react'
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardImgOverlay,
  CardTitle,
} from 'reactstrap'
import Future from './SuggestedProfilesPlaceholder.png'
import { BlockAd } from '../Advertisement'

class RightColumn extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card inverse className="mb-2 d-none d-sm-none d-md-block ">
          <CardImg src={Future} width="100%" />
          <CardImgOverlay
            style={{
              backgroundColor: '#000',
              opacity: '0.7',
            }}
          >
            <CardBody>
              <CardTitle
                className="card-title"
                style={{
                  fontSize: '1.5em',
                  margin: '0px auto',
                }}
              >
                Suggested profiles coming soon!
              </CardTitle>
              <CardText>
                Thanks for testing out NUGL 2.0 beta. We welcome all feedback as
                we continue to make the site better.
              </CardText>
            </CardBody>
          </CardImgOverlay>
        </Card>
        <div className="sticky-top sticky-offset-subnav">
          <BlockAd />
          <div className="profile-footer">
            <span className="mr-2">© 2019 NUGL</span>
            <a href="https://company.nugl.com/about.html" className="mr-2">
              About{' '}
            </a>
            <a href="https://company.nugl.com/contact.html" className="mr-2">
              Help&nbsp;Center
            </a>
            <a href="https://company.nugl.com/terms.html" className="mr-2">
              Terms{' '}
            </a>
            <a href="https://company.nugl.com/privacy.html" className="mr-2">
              Privacy&nbsp;policy
            </a>
            <a href="https://company.nugl.com/contact.html" className="mr-2">
              Contact{' '}
            </a>
            <a href="https://company.nugl.com/advertise.html" className="mr-2">
              Ads&nbsp;info{' '}
            </a>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export { RightColumn }
