import * as React from 'react'
import { firebase } from '../../firebase'

class ReportPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      posts: 0,
      users: 0,
    }
  }
  async componentDidMount() {
    this.setState({ loading: true })
    this.unsubscribe = await firebase.firestore
      .collection(`reports`)
      .onSnapshot(async snapshot => {
        const ret = {
          loading: false,
        }
        snapshot.docs.forEach(doc => {
          ret[doc.id] = doc.data().count
        })
        this.setState(ret)
      })
    // this.setState({ loading: false });
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    return (
      <div>
        Hello.
        <br />
        <br />
        {this.state.loading && <p>Loading...</p>}
        <br />
        <br />
        <br />
        Number of users: {this.state.users}
        <br />
        Number of posts: {this.state.posts}
      </div>
    )
  }
}

export { ReportPage }
