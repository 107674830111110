import * as React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { compose } from 'recompose'

import { withRouter } from 'react-router'

import { LeftColumn } from '../HomeContent/LeftColumn'
import { RightColumn } from '../HomeContent/RightColumn'
import { HomeFeed } from '../HomeFeed'
import { Following } from '../Following'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { NotificationCenter } from '../NotificationCenter'
import naturalsort from 'natural-sort'
import { firebase, notificationSub } from '../../firebase'

export class HomeComponent extends React.Component {
  state = {
    profile: null,
    notifications: {
      notifications: [],
      loaded: false,
      unreadCount: 0,
    },
    badgeCount: {
      chatter: 0,
      following: 0,
      notifications: 0,
    },
  }

  async componentDidMount() {
    await this.setCurrentUser()
  }

  componentWillUnmount() {
    this.unsubscribe()
    this.unsubscribeMyFeedPosts()
    this.unsubscribeNotifications()
  }

  setCurrentUser = async () => {
    const { onSetUsers, user } = this.props

    const userRef = (await (await firebase.firestore.doc(
      `users/${user.uid}`,
    )).get()).data()

    onSetUsers(userRef)

    const profileRef = userRef.business
      ? userRef.businessRef
      : firebase.firestore.doc(`people/${userRef.id}`)

    const following = await profileRef.collection('following').get()

    this.unsubscribeNotifications = await notificationSub(user.uid).onSnapshot(
      async snapshot => {
        const unreadCount = snapshot.docs.filter(d => !d.data().read).length
        const notifications = {
          notifications: snapshot.docs
            .sort((l, r) => r.data().data.timestamp - l.data().data.timestamp)
            .map(d => ({
              id: d.id,
              ...d.data(),
              path: `notifications/${userRef.id}/queue/${d.id}`,
            })),
          loaded: true,
          unreadCount,
        }
        this.setState({
          notifications,
          badgeCount: { ...this.state.badgeCount, notifications: unreadCount },
        })
      },
    )

    this.unsubscribeMyFeedPosts = firebase.firestore
      .collection(`feeds/${userRef.username}/posts`)
      .onSnapshot(async myCollection => {
        await this.loadFeeds(following, myCollection)
      })

    this.unsubscribe = profileRef.onSnapshot(snapshot => {
      this.setState({
        avatar: snapshot.data().avatar,
        cover: snapshot.data().cover,
        isBusiness: userRef.business,
        profile: userRef,
      })
    })
  }

  async getPeople(usernames) {
    const srt = naturalsort()
    const docs = (await Promise.all(
      usernames.map(async username => {
        const snap = await firebase.firestore
          .collection(`people`)
          .where('username', '==', username)
          .get()
        const docs = snap.docs
        if (docs.length === 0) {
          return null
        }
        return docs[0]
      }),
    ))
      .filter(doc => doc !== null)
      .map(doc => doc.data())
      .sort((aDoc, bDoc) =>
        srt(aDoc.name || aDoc.username, bDoc.name || bDoc.username),
      )
    return docs
  }

  loadFeeds = async (following, myCollection) => {
    const followingUsernames =
      following.size > 0
        ? await Promise.all(
            await following.docs
              .filter(e => e.data().feedRef)
              .map(async e => {
                return await e.data().feedRef.id
              }),
          )
        : []

    const followingPeople = await this.getPeople(followingUsernames)

    const collections =
      following.size > 0
        ? await Promise.all(
            await following.docs
              .filter(e => e.data().feedRef)
              .map(
                async e =>
                  await e
                    .data()
                    .feedRef.collection('posts')
                    .get(),
              ),
          )
        : []

    const snapshots = [...collections, myCollection].reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        ...(currentValue.docs || []),
      ],
      [],
    )

    const posts = snapshots
      .map(snapshot => {
        return { data: snapshot.data(), ref: snapshot.ref }
      })
      .sort((a, b) => b.data.timestamp - a.data.timestamp)
      .map(e => e.ref)

    this.setState({
      following: followingPeople,
      posts,
      badgeCount: {
        ...this.state.badgeCount,
        chatter: posts.length,
        following: followingPeople.length,
      },
    })
  }

  render() {
    const section = this.props.match.params.subpath
    return (
      <React.Fragment>
        {this.state.profile && (
          <React.Fragment>
            <Container>
              <div className="public-profile">
                <Row noGutters>
                  <Col md={4} xl={3} className="px-1">
                    <LeftColumn
                      section={section}
                      profile={this.state.profile}
                      badgeCount={this.state.badgeCount}
                    />
                  </Col>
                  <Col id="feedPosts" md={8} xl={6} className="px-1">
                    {(!section || section === 'chatter') && (
                      <HomeFeed
                        profile={this.state.profile}
                        posts={this.state.posts}
                        loading={false}
                      />
                    )}
                    {section === 'following' && (
                      <Following
                        profile={this.state.profile}
                        following={this.state.following}
                      />
                    )}
                    {section === 'notifications' && (
                      <NotificationCenter
                        notifications={this.state.notifications.notifications}
                        unreadCount={this.state.notifications.unreadCount}
                        loaded={this.state.notifications.loaded}
                      />
                    )}
                  </Col>
                  <Col md={3} className="px-1">
                    <RightColumn />
                  </Col>
                </Row>
              </div>
            </Container>
          </React.Fragment>
        )}
        {!this.state.profile && <FontAwesomeIcon icon={faSpinner} spin />}
      </React.Fragment>
    )
  }
}

class HomeWrapper extends React.PureComponent {
  render() {
    if (!this.props.user) {
      return null
    } else {
      return <HomeComponent {...this.props} />
    }
  }
}

const mapStateToProps = state => ({
  user: state.sessionState.authUser,
})

const mapDispatchToProps = dispatch => ({
  onSetUsers: user => dispatch({ type: 'SET_USER', user }),
})

export const Home = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(HomeWrapper)
